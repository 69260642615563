import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { UserService } from 'app/shared/services/user.service';
import { GeocodingApiService } from 'app/shared/services/geocoding-api-service.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from 'app/shared/services/firebase.service';

@Component({
  selector: 'app-shareproject',
  templateUrl: './shareproject.component.html',
  styleUrls: ['./shareproject.component.scss']
})
export class ShareprojectComponent implements OnInit {
  @Input() public project;
  @Input() public dayArray;

  updated = false;

  possibleDays = Array<any>();
  possibleUsers = Array<any>();
  users: any;

  ModalTitle = 'Freigeben';
  myForm: FormGroup;
  constructor(private userService: UserService,
    private geocodingApiService: GeocodingApiService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseService
  ) {

  }

  ngOnInit() {
    this.buildItemForm();
    this.getPossibleUsers();
  }

  userSelected(user) {

  }
  getformUsers () { return <FormArray>this.myForm.get('users'); }

  addUser(): void {
    this.users = this.myForm.get('users') as FormArray;
    this.users.push(this.createUser());
  }

  createUser(): FormGroup {
    return this.formBuilder.group({
      user: '',
    });
  }

  private buildItemForm() {
    this.myForm = this.formBuilder.group({
      users: this.formBuilder.array([this.createUser()])
    });
  }

  submitForm() {
    var users = this.myForm.value['users'];
    var everbodySelected = false;
    users.forEach(user => {
      console.log(user.user);
      if (user.user == 'everybody') {
        everbodySelected = true;
      }
    });
    if (everbodySelected) {
      this.firebaseService.publishJobForEveryone(this.project);
    } else {
      console.log(users);
      this.firebaseService.publishJobForUsers(this.project, users);
    }
    this.close();
  }

  close() {
    this.activeModal.dismiss();
  }

  async getPossibleUsers() {
    var snap = await this.firebaseService.getPossibleUsersForJob(this.project).toPromise();
    snap.forEach(async user => {
      var data = user.data();
      data['id'] = user.id;

      this.possibleUsers.push(data);
      this.possibleUsers.sort((a, b) => a['possibleDays'].length.compareTo(b['possibleDays'].length));
    });
  }
}
