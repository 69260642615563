import { Hospital } from 'app/hospitals/hospitals/hospital.model';
import { Company } from './company.model';

export class Reseller {
    static transformToPlainJSObject(reseller: Reseller) {
        reseller.company = Company.tansformToPlainJsObject(reseller.company);
        return Object.assign({}, reseller);
    }

    public name: string;
    public address: string;
    public companyKey: string;
    public company: Company;
    public adminEMail: String;
    public adminName: String;
    public city: String;
    public cityCode: String;
    public country: String;
    public street: String;
    public testHospitalId: String;
    public testmode: boolean;

    constructor(testHospitalId?: string, testmode?: boolean, city?: string,cityCode?: string,country?: string,street?: string,name?: string, address?: string, companyKey?: string, company?: Company, adminEMail?: String, adminName?: String);
    constructor(testHospitalId: string, testmode: boolean, city: string,cityCode: string,country: string,street: string,name: string, address: string, companyKey: string, company: Company, adminEMail: String, adminName: String) {
        this.name = name;
        this.address = address;
        this.companyKey = companyKey;
        this.company = company;
        this.adminEMail = adminEMail;
        this.adminName = adminName;
        this.city = city;
        this.cityCode = cityCode;
        this.country = country;
        this.street = street;
        this.testmode = testmode;
        this.testHospitalId = testHospitalId;
    }


    static fromJson(arg0: any): Reseller {
        var returnReseller = new Reseller();
        returnReseller.name = arg0['name'];
        returnReseller.address = arg0['address'];
        returnReseller.city = arg0['city'];
        returnReseller.cityCode = arg0['cityCode'];
        returnReseller.testHospitalId = arg0['testHospitalId'];
        returnReseller.country = arg0['country'];
        returnReseller.street = arg0['street'];
        returnReseller.adminEMail = arg0['adminEMail'];
        returnReseller.adminName = arg0['adminName'];
        returnReseller.companyKey = arg0['companyKey'];
        returnReseller.testmode = arg0['testmode'];
        if (arg0['company'] != null) {
            returnReseller.company = Company.fromJson(arg0['company']);
        }

        return returnReseller;
    }
}
