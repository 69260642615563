import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from 'app/shared/services/firebase.service';
import { UserService } from 'app/shared/services/user.service';
import { GeocodingApiService } from 'app/shared/services/geocoding-api-service.service';
import { AdditionalUserData } from 'app/models/additionalData';
import { isThisSecond } from 'date-fns';
import { InfopopupComponent } from '../infopopup/infopopup.component';

@Component({
  selector: 'app-forceapplication',
  templateUrl: './forceapplication.component.html',
  styleUrls: ['./forceapplication.component.scss']
})
export class ForceapplicationComponent implements OnInit {
  @Input() public project;
  @Input() public dayArray;
  @Input() public selectedSubPositionNumber;

  updated = false;
  selectedDates = Array<any>();

  possibleDays = Array<any>();
  possibleUsers = Array<any>();
  selectedUser: any;
  selected = 'option2';

  ModalTitle = '';
  myForm: FormGroup;
  constructor(private userService: UserService,
    private modalService: NgbModal, 
    private geocodingApiService: GeocodingApiService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseService
  ) {

  }

  ngOnInit() {
    this.buildItemForm();
    this.getPossibleUsers();
  }

  userSelected(user) {

  }

  private buildItemForm() {
    this.myForm = this.formBuilder.group({

      user: ["", Validators.required]
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  async getDistanceBetweenUserAndHospital(hospitalAddress: string, userAddress: string) {
    var response = await this.geocodingApiService.getDistanceBetweenAdresses(hospitalAddress, userAddress).toPromise();
    if (response.status == 'OK') {
      if (response['routes'] != null && response['routes']['legs'] != null && response['routes']['legs'].length > 0 && response['routes']['legs'][0] != null && response['routes']['legs'][0]['distance'] != null) {
        return response['routes']['legs'][0]['distance']['value'];
      }
    } else if (response.status == 'ZERO_RESULTS') {
      console.log('geocodingAPIService', 'ZERO_RESULTS', response.status);
    } else {
      console.log('geocodingAPIService', 'Other error', response.status);
    }
    return null;
  }

  doesUserHaveQualifications(user) {
    var allQualificationsFound = true;

    this.project.qualifications.forEach(requiredQualification => {

      var foundQual = false;
      user.qualifications.forEach(userQual => {
        if (userQual.shortcut == requiredQualification) {
          foundQual = true;
        }
      });
      if (!foundQual) {
        console.log(requiredQualification);
        allQualificationsFound = false;
      }

    });
    return allQualificationsFound;
  }

  getPossibleDaysForUser(user) {
    var possibleDates = [];
    if (user['bookedDates'] == null) {
      possibleDates = this.dayArray;
    } else {
      possibleDates = this.dayArray.filter((day: { date: string; }) => !user['bookedDates'].includes(day.date));
    }
    return possibleDates;
  }

  async isHospitalInRange(user) {
    var isInRange = true;
    // var distance = await this.getDistanceBetweenUserAndHospital(`${user['street'].replace(/ /g, '+')},${user['city'].replace(/ /g, '+')}`, `${this.project.hospital.address.replace(/ /g, "+")}`);
    // var userRange = user['workRange'];
    // if (userRange < distance) {
    //   isInRange = false;
    // };
    return isInRange;
  }

  userChanged(user) {
    this.selectedUser = user;
    this.possibleDays = user['possibleDays'];
  }



  onSelected(event) {
    if (this.selectedDates == null || this.selectedDates.length == 0) {
      this.selectedDates = event.dates;
    } else {
      event.dates.forEach(element => {
        var deleted = false;
        var i = this.selectedDates.length;
        while (i--) {
          if (this.selectedDates[i].getTime() == element.getTime()) {
            this.selectedDates.splice(i, 1);
            deleted = true;
          }
        }
        if (!deleted) {
          this.selectedDates.push(element);
        }
      });
    }
    this.updated = !this.updated;
  }

  async getPossibleUsers() {
    var snap = await this.firebaseService.getPossibleUsersForJob(this.project).toPromise();
    snap.forEach(async user => {
      var data = user.data();
      data['id'] = user.id;
      // Has qualifications
      var qualified = this.doesUserHaveQualifications(data);

      console.log(qualified);
      // Is not busy
      var possibleDaysForUser = this.getPossibleDaysForUser(data);

      // Is in Radius
      var isInRadius = await this.isHospitalInRange(data);

      if (qualified && possibleDaysForUser.length > 0 && isInRadius) {
        data['possibleDays'] = possibleDaysForUser;
        this.possibleUsers.push(data);
        this.possibleUsers.sort((a, b) => a['possibleDays'].length.compareTo(b['possibleDays'].length));
      }
    });

  }

  submitForm() {
    this.firebaseService.createApplication(this.project, this.selectedDates, this.selectedUser['id'], this.selectedUser, this.selectedSubPositionNumber);
    this.activeModal.close();
  }

  async showInformation() {
    
    const modalDialog = this.modalService.open(InfopopupComponent);
    modalDialog.componentInstance.text = "Sie können den Einsatz einer/einem Mitarbeiter*in zuweisen. Bitte wählen Sie dafür den/die Mitarbeiter*in und wählen Sie dann im Kalender für welche Tage diese(r) gebucht werden soll.";
    modalDialog.componentInstance.title = "Mitarbeiter*in zuweisen";

    await modalDialog.result;
  }
}
