import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('../../calendar/calendar.module').then(m => m.CalendarsModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'users',
    loadChildren: () => import('../../users/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'departments',
    loadChildren: () => import('../../departments/departments.module').then(m => m.DepartmentsModule)
  },
  {
    path: 'hospitals',
    loadChildren: () => import('../../hospitals/hospitals/hospitals.module').then(m => m.HospitalsModule)
  },
  {
    path: 'qr',
    loadChildren: () => import('../../qr-screen/qr-screen.module').then(m => m.QrScreenModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('../../projects/projects/projects.module').then(m => m.ProjectsModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('../../admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'findreseller',
    loadChildren: () => import('../../findreseller/findreseller.module').then(m => m.FindResellerModule)
  },
  {
    path: 'myzaf',
    loadChildren: () => import('../../myzaf/myzaf.module').then(m => m.MyzafModule)
  },
  {
    path: 'myhospital',
    loadChildren: () => import('../../myhospital/myhospital.module').then(m => m.MyHospitalModule)
  },

];
