export let Qualifications = {
    MTA: "Medizintechnische Assistenz",
    MTRA: "Medizintechnische Radiologieassistenz",
    MTLA: "Medizintechnische Laborassistenz",
    PD: "Pflegedienst",
    FPD: "Fachpflegedienst",
    PA: "Pflegeassistenz",
    AP: "Altenpflege",
    APH: "Altenpflegehelfer",
    KKPF: "Kinderkrankenpflege",
    FKKPF: "Fachkinderkrankenpflege",
    HEB: "Hebammen AD Anästhesiedients",
    ENDO: "Endoskopie Pflege",
    PT: "Physiotherapie",
    HA: "Haushaltshilfe",
}