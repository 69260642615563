import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from '../services/user.service';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private firebaseAuth: AngularFireAuth, private authService: AuthService, private userService: UserService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.firebaseAuth.authState
      .take(1)
      .map(authState => !!authState)
      .do(auth => {
        if (auth) {
          this.userService.setUser(this.firebaseAuth.auth.currentUser)
        }
      })
      .do(auth => !auth ? this.router.navigate(['/pages/splash']) : true)
  }
}
