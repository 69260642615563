import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService as FbAuthService, FacebookLoginProvider } from "angularx-social-login";
import * as firebase from 'firebase';

@Injectable()
export class AuthService {
  token: string;
  authState: any = null;

  constructor(private fbAuthService: FbAuthService, private af: AngularFireAuth) {

    this.af.authState.subscribe((auth) => {
      this.authState = auth
    });
  }


  anonymousLogin() {
    return this.af.auth.signInAnonymously()
      .then(() => console.log("successful login"))
      .catch(error => console.log(error));
  }

  async loginWithFacebook() {
    var platform = FacebookLoginProvider.PROVIDER_ID;
    var response = await this.fbAuthService.signIn(platform);
    var cred = firebase.auth.FacebookAuthProvider.credential(response.authToken);
    return this.af.auth.signInWithCredential(cred);
  }

  async loginWithUsernameAndPassword(username, password) {
    return this.af.auth.signInWithEmailAndPassword(username, password);
  }

  signUpWithUsernameAndPassword(username, password) {
    return this.af.auth.createUserWithEmailAndPassword(username, password);
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
  }

  async logout() {
    await this.af.auth.signOut();
    this.token = null;
    return true;
  }

  getToken() {
    return this.token;
  }

  isAuthenticated() {
    return this.authState;
    // return this.af.authState.pipe(first()).toPromise();
    // here you can check if user is authenticated or not through his token 
  }
}
