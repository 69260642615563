import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ZA_ROUTES: RouteInfo[] = [
    {
        path: '/dashboard/dashboard1', title: 'Dashboard', icon: 'ft-home', class: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', badge: '', isExternalLink: false, submenu: []
    },
    { path: '/hospitals', title: 'Krankenhäuser', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/projects', title: 'Zu vermittelnde Projekte', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/users', title: 'Zeitarbeiter', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    { path: '/users', title: 'Meine Zeitarbeitsfirma - TODO', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/users', title: 'Laufende Vermittlungen - TODO', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    // { path: '/chat', title: 'Chat', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    // { path: '/taskboard', title: 'Task Board', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    // {
    //     path: '/maps/google', title: 'Google Map', icon: 'ft-map', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    // },
    { path: '/calendar', title: 'Calendar', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
        path: '/pages/logout', title: 'Logout', icon: 'ft-home', class: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', badge: '', isExternalLink: false, submenu: []
    },
];

export const RESELLER_ROUTES: RouteInfo[] = [
    {
        path: '/dashboard/dashboard1', title: 'Home', icon: 'ft-home', class: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', badge: '', isExternalLink: false, submenu: []
    },

    {
        path: '/projects/publishedProjects', title: 'Einsätze', icon: 'ft-align-left', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
        submenu: [
            { path: '/projects/newProjects', title: 'Neue', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], dependantOnPreference: "publishAutomatically" },
            { path: '/projects/publishedProjects', title: 'Offene', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/projects/distributedProjects', title: 'Vermittelte', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/projects/activeProjects', title: 'Vermittelte', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/projects/finishedProjects', title: 'Abgeschlossene', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/projects/archivedProjects', title: 'Archiv', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/projects/projectDetails', title: 'Einsatz erstellen', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '/hospitals', title: 'Kunden', icon: 'ft-link-2', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
        submenu: [

            { path: '/hospitals', title: 'Übersicht', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/myzaf/findhospital', title: 'Kunden suchen', icon: 'ft-search', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/myzaf/hospitalLinks', title: 'Verknüpfungsanfragen', icon: 'ft-link', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/hospitals/hospitalDetails', title: 'Neuen Kunden', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },

    // { path: '/projects', title: 'Projekte', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    // { path: '/users', title: 'Benutzer', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
        path: '/users/za', title: 'Zeitarbeiter', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu:
            [
                { path: '/users/za', title: 'Übersicht', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/users/zadetails', title: 'Neuen Zeitarbeiter', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], dependantOnPreference: 'INVITE_ZA' },
            ]
    },
    { path: '/chat', title: 'Nachrichten', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    // { path: '/users/za', title: 'Personalplanung', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    {
        path: '/myzaf/masterdata', title: 'Stammdaten', icon: 'ft-folder', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/myzaf/masterdata', title: 'Allgemein', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/myzaf/personalplanning', title: 'Personalplanung', icon: 'ft-user-check', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            {
                path: '/users', title: 'Disponent*innen', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                    { path: '/users/userdetails', title: 'Neue*r Disponent*in', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], dependantOnPreference: "INVITE_USERS" },]
            },
            // { path: '/users/za', title: 'Benutzer pflegen', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '/pages/logout', title: 'Logout', icon: 'ft-home', class: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', badge: '', isExternalLink: false, submenu: []
    },
    // { path: '/calendar', title: 'Calendar', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
];


export const ADMIN_ROUTES: RouteInfo[] = [
    // {
    //     path: '/dashboard/dashboard1', title: 'Dashboard', icon: 'ft-home', class: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', badge: '', isExternalLink: false, submenu: []
    // },
    {
        path: '/admin/resellers', title: 'Zeitarbeitsfirmen', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/admin/hospitals', title: 'Krankenhäuser', icon: 'ft-briefcase', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
            { path: '/admin/hospitals/edit', title: 'Neues Krankenhaus', icon: 'ft-briefcase', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },

    {
        path: '/pages/logout', title: 'Logout', icon: 'ft-log-out', class: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', badge: '', isExternalLink: false, submenu: []
    },
    //TODO abrechnung

];


export const HOSPITAL_ROUTES: RouteInfo[] = [
    {
        path: '/dashboard/hospitaldashboard', title: 'Home', icon: 'ft-home', class: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', badge: '', isExternalLink: false, submenu: []
    },


    {
        path: '/projects/publishedProjects', title: 'Externe Einsätze', icon: 'ft-briefcase', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
        submenu: [
            { path: '/projects/publishedProjects', title: 'Offene', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/projects/appliedProjects', title: 'In Vermittlung', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/projects/distributedProjects', title: 'Vermittelte', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/projects/finishedProjects', title: 'Abgeschlossene', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/projects/archivedProjects', title: 'Archiv', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            {
                path: '/myhospital/zafpartners', title: 'Zeitarbeitspartner', icon: 'ft-link', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                    {
                        path: '/findreseller', title: 'Partner suchen', icon: 'ft-search', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
                    },
                    {
                        path: '/myhospital/linkrequests', title: 'Verknüpfungsanfragen', icon: 'ft-link', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
                    },]
            },
        ]
    },
    {
        path: '/projects/internal/publishedProjects', title: 'Interne Vermittlungen', icon: 'ft-briefcase', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
        submenu: [
            { path: '/projects/internal/publishedProjects', title: 'Offene', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/projects/internal/appliedProjects', title: 'In Vermittlung', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/projects/internal/distributedProjects', title: 'Vermittelte', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/projects/internal/finishedProjects', title: 'Abgeschlossene', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/projects/internal/archivedProjects', title: 'Archiv', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            {
                path: '/users/internalusers', title: 'Mitarbeiter', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                    { path: '/users/internaluserdetails', title: 'Neue*r Mitarbeiter*in', icon: 'ft-user-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },]
            },
        ]
    },
    { path: '/projects/projectDetails', title: 'Einsatz erstellen', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/chat', title: 'Nachrichten', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
        path: '/myhospital/masterdata', title: 'Stammdaten', icon: 'ft-folder', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/myhospital/masterdata', title: 'Allgemein', icon: 'ft-chevron-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

            {
                path: '/users', title: 'Mitarbeiter*innen', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                    { path: '/users/userdetails', title: 'Neue*r Mitarbeiter*in', icon: 'ft-user-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },]
            },

            // {
            //     path: '/departments', class: 'has-sub', title: 'Abteilungen', icon: 'ft-plus', badge: '', badgeClass: '', isExternalLink: false, submenu: [
            //         { path: '/departments/departmentDetails', title: 'Neue Abteilung', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
            //         ,]
            // },
            // { path: '/qr', title: 'QR', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: '/users/za', title: 'Benutzer pflegen', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '/pages/logout', title: 'Logout', icon: 'ft-log-out', class: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', badge: '', isExternalLink: false, submenu: []
    },
];