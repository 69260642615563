import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { Hospital } from 'app/hospitals/hospitals/hospital.model';
import { Project } from 'app/projects/projects/project.model';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/firestore';
import { Reseller } from 'app/admin/resellers/reseller.model';
import { Company } from 'app/admin/resellers/company.model';
import { AngularFireStorage } from '@angular/fire/storage';
import { DatePipe } from '@angular/common';
import * as firebase from 'firebase/app';
import { Message } from 'app/models/message';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IdnowService } from './idnow.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  // getProfileImageUrl(email) {
  //   return this.afStorage.ref('/users/' + email + "/profilePicture").getDownloadURL();
  // }

  constructor(private idNowService: IdnowService, private http: HttpClient, public datepipe: DatePipe, private afStorage: AngularFireStorage, private firestore: AngularFirestore, private firebaseAuth: AngularFireAuth, private userService: UserService) { }

  async updateUser(values: any, userKey) {
    return await this.firestore.collection('users').doc(userKey).update(values);
  }

  sendInvitationEmail(email) {
    var html = '<p style="font-size: 16px;">Sie können sich nun <a href="https://app.shyftapp.de/pages/register">hier</a> registrieren.</p>';
    let url = 'https://us-central1-bbflutter-1a260.cloudfunctions.net/sendMail';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });

    let body = { "email": email, "html": html, "subject": "Einladung zu SHYFT erhalten" };

    this.http.post(url, body, { headers: headers }).toPromise().then(res => {

    }).catch(error => {

    });
  }

  async saveContractDetails(userId, projectId, details, file) {

    var docName = projectId + "" + userId;
    this.firestore.collection('contracts').doc(docName).set(details);
  }


  async saveZAFUser(values: any) {
    var emailWithDot = values['email'];
    var email = values['email'].split('.').join(',');
    values['resellerId'] = this.userService.getAdditionalData().resellerId.toString();
    values['role'] = "RS";
    this.sendInvitationEmail(emailWithDot);
    return await this.firestore.collection('userInfo').doc(email).set(values);
  }

  async saveHPUser(values: any) {
    var emailWithDot = values['email'];
    var email = values['email'].split('.').join(',');
    values['hospitalId'] = this.userService.getAdditionalData().hospitalId;
    values['role'] = "HP";
    this.sendInvitationEmail(emailWithDot);
    return await this.firestore.collection('userInfo').doc(email).set(values);
  }

  getListOfFileNames(path) {
    var ref = this.afStorage.storage.ref(path);
    return ref.listAll();
  }

  getResellerData() {
    return this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).get().toPromise();
  }

  getResellerDataForId(id) {
    return this.firestore.collection('reseller').doc(id).get().toPromise();
  }

  getApplicationsForProject(projectKey: string) {
    return this.firestore.collection('projects').doc(projectKey).collection('applications').snapshotChanges();
  }

  getProjectDetail(projectKey: string) {
    return this.firestore.collection('projects').doc(projectKey).get();
  }

  getNumberOfProjectsForHospital(id: any) {
    return this.firestore.collection('counts').doc(id).get();
  }

  getNewKey() {
    return this.firestore.createId();
  }

  getPossibleUsersForJob(project: any) {
    if (this.userService.getAdditionalData().role == 'RS') {
      return this.firestore.collection('users', ref => ref.where("resellerId", "==", this.userService.getAdditionalData().resellerId.toString()).where("job", "==", project.job)).get();

    } else {
      return this.firestore.collection('users', ref => ref.where("hospitalId", "==", this.userService.getAdditionalData().hospitalId.toString()).where("role", "==", 'INTUSER').where("job", "==", project.job)).get();

    }
  }


  async saveReseller(reseller: import("../../admin/resellers/reseller.model").Reseller, isNew: boolean) {
    Object.keys(reseller).forEach((key) => (reseller[key] == null) && delete reseller[key]);
    var resellerKey = "";
    if (isNew) {
      var company = reseller.company;
      Object.keys(company).forEach((key) => (company[key] == null) && delete company[key]);
      var companyKey = this.firestore.createId();
      await this.firestore.collection('companies').doc(companyKey).set(Company.tansformToPlainJsObject(reseller.company));
      reseller.companyKey = companyKey;
      resellerKey = this.firestore.createId();
      await this.firestore.collection('reseller').doc(resellerKey).set(Reseller.transformToPlainJSObject(reseller));

    } else {
      resellerKey = this.firestore.createId();
      await this.firestore.collection('reseller').doc(resellerKey).set(Reseller.transformToPlainJSObject(reseller));
    }
    await this.firestore.collection('userInfo').doc(reseller.adminEMail.split('.').join(',')).set({
      name: reseller.adminName, resellerId: resellerKey, role: 'RS', permissions: [


        { 'key': 'EDIT_USERS', val: 'Standortmitarbeiter verwalten' },
        { 'key': "INVITE_USERS", val: "Standortmitarbeiter einladen" },
        { 'key': "EDIT_USERS", val: "Standortmitarbeiter verwalten" },
        { 'key': "CHANGE_PERMISSIONS", val: "Standortmitarbeiter Berechtigungen verwalten" },
        { 'key': "INVITE_ZA", val: "Zeitarbeiter einladen" },
        { 'key': "EDIT_ZA", val: "Zeitarbeiter verwalten" },
        { 'key': "MANAGE_DOCUMENTS", val: "AÜV" },
        { 'key': "MANAGE_PROJECTS", val: "Projekte verwalten" }

      ]
    });
    return resellerKey;
  }

  getAllResellers(): Observable<firebase.firestore.QuerySnapshot> {
    return this.firestore.collection('reseller', ref => ref.where('testmode', "==", false)).get();
  }


  getAllResellersWithTestmodes(): Observable<firebase.firestore.QuerySnapshot> {
    return this.firestore.collection('reseller').get();
  }

  getAllCompanies(): Observable<any> {
    return this.firestore.collection('companies').get();
  }

  async createLinkRequestForReseller(resellerId) {
    this.firestore.collection('reseller').doc(resellerId).collection('linkRequests').doc(this.userService.getAdditionalData().hospitalId).set({ 'requested': true });
  }

  async linkResellerAndHospital(resellerId, hospitalId) {
    var hospitalObs = this.getHospitalDetails(hospitalId);
    var hospital = (await hospitalObs.toPromise()).data();
    var resellers = hospital['resellers'];
    if (resellers == null) {
      resellers = [];
    }
    if (!resellers.includes(resellerId)) {
      resellers.push(resellerId);
      var cityCode = hospital['cityCode'];
      this.firestore.collection('reseller').doc(resellerId).collection('hospitalCityCodes').doc(cityCode).set({ wasInitialized: false });
      this.firestore.collection('hospitals').doc(hospitalId).update({ 'resellers': resellers });
    }

    var resellerObs = await this.getResellerDataForId(resellerId);
    var reseller = resellerObs.data();
    var hospitals = reseller['hospitals'];
    if (hospitals == null) {
      hospitals = [];
    }
    if (!hospitals.includes(hospitalId)) {
      hospitals.push(hospitalId);
      this.firestore.collection('reseller').doc(resellerId).update({ 'hospitals': hospitals });
    }
  }


  async acceptLinkRequestFromHospitalId(hospitalId) {
    // Delete link request from resellerlinkrequests
    this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).collection('linkRequests').doc(hospitalId).delete();

    var hospitalObs = this.getHospitalDetails(hospitalId);
    var hospital = (await hospitalObs.toPromise()).data();
    var resellers = hospital['resellers'];
    if (resellers == null) {
      resellers = [];
    }
    if (!resellers.includes(this.userService.getAdditionalData().resellerId)) {
      resellers.push(this.userService.getAdditionalData().resellerId);
      var cityCode = hospital['cityCode'];
      this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).collection('hospitalCityCodes').doc(cityCode).set({ wasInitialized: false });
      this.firestore.collection('hospitals').doc(hospitalId).update({ 'resellers': resellers });
    }

    var resellerObs = await this.getResellerDataForId(this.userService.getAdditionalData().resellerId);
    var reseller = resellerObs.data();
    var hospitals = reseller['hospitals'];
    if (hospitals == null) {
      hospitals = [];
    }
    if (!hospitals.includes(hospitalId)) {
      hospitals.push(hospitalId);
      this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).update({ 'hospitals': hospitals });
    }
  }

  async createLinkRequestForHospital(hospitalId) {

    this.firestore.collection('hospitals').doc(hospitalId).collection('linkRequests').doc(this.userService.getAdditionalData().resellerId).set({ 'requested': true });
    return true;
  }

  async acceptLinkRequestFromReseller(resellerId) {
    this.firestore.collection('hospitals').doc(this.userService.getAdditionalData().hospitalId).collection('linkRequests').doc(resellerId).delete();
    var hospitalObs = this.getHospitalDetails(this.userService.getAdditionalData().hospitalId);
    var hospital = (await hospitalObs.toPromise()).data();
    var resellers = hospital['resellers'];
    if (resellers == null) {
      resellers = [];
    }
    if (!resellers.includes(resellerId)) {
      resellers.push(resellerId);
      var cityCode = hospital['cityCode'];
      this.firestore.collection('reseller').doc(resellerId).collection('hospitalCityCodes').doc(cityCode).set({ wasInitialized: false });

      this.firestore.collection('hospitals').doc(this.userService.getAdditionalData().hospitalId).update({ 'resellers': resellers });
    }


    var resellerObs = await this.getResellerDataForId(resellerId);
    var reseller = resellerObs.data();
    var hospitals = reseller['hospitals'];
    if (hospitals == null) {
      hospitals = [];
    }
    if (!hospitals.includes(this.userService.getAdditionalData().hospitalId)) {
      hospitals.push(this.userService.getAdditionalData().hospitalId);
      this.firestore.collection('reseller').doc(resellerId).update({ 'hospitals': hospitals });
    }

    return true;
  }

  updateCityCodesForUser(userId, cityCodes) {
    this.firestore.collection('users').doc(userId).update({ assignedCityCodes: cityCodes });
  };


  getUserHospital() {
    return this.firestore.collection('hospitals').doc(this.userService.getAdditionalData().hospitalId).get();
  }

  decrementUnsignedCounter(projectKey: string) {
    this.firestore.collection('projects').doc(projectKey).update({ "unsignedTimesheetCount": firebase.firestore.FieldValue.increment(-1) })
  }

  addMessage(msg: import("../../models/message").Message) {
    var msgJson = msg.toJson();
    Object.keys(msgJson).forEach(key => msgJson[key] === undefined && delete msgJson[key]);
    this.firestore.collection('messages').add(msgJson);
    this.addChatEntryForUser(msg);
  }

  addChatEntryForUser(msg: import("../../models/message").Message) {
    var msgJson = msg.toJson();
    Object.keys(msgJson).forEach(key => msgJson[key] === undefined && delete msgJson[key]);
    this.firestore.collection('users').doc(msg.recipientId).collection('chats').doc(msg.senderId).collection('messages').add(msgJson);
    console.log(msg.receiverName);
    this.firestore.collection('users').doc(msg.recipientId).collection('chats').doc(msg.senderId).set({ partnerName: msg.senderName, lastMessage: msg.text });
    this.firestore.collection('users').doc(msg.senderId).collection('chats').doc(msg.recipientId).collection('messages').add(msgJson);
    this.firestore.collection('users').doc(msg.senderId).collection('chats').doc(msg.recipientId).set({ partnerName: msg.receiverName, lastMessage: msg.text });
  }

  getMasterData(resellerId) {
    return this.firestore.collection('reseller').doc(resellerId).get();
  }

  getHospitalDetails(hospitalId) {
    return this.firestore.collection('hospitals').doc(hospitalId).get();
  }

  getLinkRequests(hospitalId) {
    return this.firestore.collection('hospitals').doc(hospitalId).collection('linkRequests').get();
  }

  getLinkRequestsForReseller(resellerId) {
    return this.firestore.collection('reseller').doc(resellerId).collection('linkRequests').get();
  }

  changeToConfirmed(project: Project, application: any, userKey: string, arrayNumber, confirmedDates, shouldChangeState, resellerId) {
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    var monthAndYear = month + "-" + year;

    var chosenUsers = project.chosenUsers != null ? project.chosenUsers : Array<string>();
    chosenUsers.push(userKey);

    this.firestore.collection('users').doc(userKey).update({ bookedDates: confirmedDates });
    confirmedDates.forEach(confirmedDate => {
      this.firestore.collection('hospitals').doc(project.hospital.id).collection('dayWorkerMapping').doc(confirmedDate).collection('workers').add({ 'user': userKey, station: project.station });
    });
    this.firestore.collection('projects').doc(project.projectKey).collection('applications').doc(userKey).set(application);
    this.firestore.collection('projects').doc(project.projectKey).collection('activeUsers').doc(userKey).set({ 'finished': false });
    if (shouldChangeState) {
      if (project.internal) {
        monthAndYear += "-internal";
      }
      this.firestore.collection('hospitals').doc(project.hospital.id).collection('statistics').doc('distributedProjectCount').set({ [monthAndYear]: firebase.firestore.FieldValue.increment(1) }, { merge: true });
      this.firestore.collection('projects').doc(project.projectKey).update({ "chosenUsers": chosenUsers, "currentlyInZafRelevantState": firebase.firestore.FieldValue.increment(1), "currentlyInHospitalRelevantState": firebase.firestore.FieldValue.increment(-1), dayArrays: project.dayArrays, state: 'DISTRIBUTED' });
    } else {
      this.firestore.collection('projects').doc(project.projectKey).update({ "chosenUsers": chosenUsers, "currentlyInZafRelevantState": firebase.firestore.FieldValue.increment(1), "currentlyInHospitalRelevantState": firebase.firestore.FieldValue.increment(-1), dayArrays: project.dayArrays });
    }
    console.log(resellerId);
    if (resellerId != null) {
      this.firestore.collection('reseller').doc(resellerId).collection('statistics').doc('distributedProjectCount').set({ [monthAndYear]: firebase.firestore.FieldValue.increment(1) }, { merge: true });
    }
  }

  changeToContractTransmitted(project: Project, application: any, userKey: string, arrayNumber) {
    this.firestore.collection('projects').doc(project.projectKey).update({ dayArrays: project.dayArrays });
    this.firestore.collection('projects').doc(project.projectKey).collection('applications').doc(userKey).set(application);
  }

  changeApplicationStatus(project, userKey, status) {
    this.firestore.collection('projects').doc(project.projectKey).collection('applications').doc(userKey).update({ 'state': status });
  }

  changeToContractUploaded(project: Project, application: any, userKey: string, arrayNumber) {
    this.firestore.collection('projects').doc(project.projectKey).update({ dayArrays: project.dayArrays });
    this.firestore.collection('projects').doc(project.projectKey).collection('applications').doc(userKey).set(application);
  }

  publishJobForUsers(project, users) {
    var newUserArray = [];
    if (project.publishedFor != null) {
      project.publishedFor.forEach(alreadyPublished => {
        newUserArray.push(alreadyPublished);
      });
    }
    users.forEach(newUser => {
      if (!newUserArray.includes(newUser.id)) {
        newUserArray.push(newUser['user']['id']);
      }
    });
    this.firestore.collection('projects').doc(project.projectKey).update({ publishedFor: newUserArray, dayArrays: project.dayArrays });
  }

  publishJobForEveryone(project) {
    this.firestore.collection('projects').doc(project.projectKey).update({ publishedForEveryone: true, dayArrays: project.dayArrays });
  }

  addUserTag(timeLineEvent) {
    if (this.userService.getAdditionalData().name != null) {
      timeLineEvent['user'] = this.userService.getAdditionalData().name;

    }
    timeLineEvent['user'] = "";
  }


  archiveProjectForHospital(project: Project) {

    var timeLineEvent = {};
    this.addUserTag(timeLineEvent);

    timeLineEvent['state'] = "ARCHIVED";

    timeLineEvent['time'] = this.datepipe.transform(new Date(), "HHmm");;
    timeLineEvent['date'] = this.datepipe.transform(new Date(), "yyyyMMdd");
    var dateString = this.datepipe.transform(new Date(), "yyyyMMddHHmmss");
    project.timeline[dateString] = timeLineEvent;

    const index = project.resellers.indexOf(this.userService.getAdditionalData().resellerId, 0);
    if (index > -1) {
      project.resellers.splice(index, 1);
    }

    Object.keys(project).forEach(key => project[key] === undefined && delete project[key]);
    this.firestore.collection('projects').doc(project.projectKey).update({ state: 'ARCHIVED' });
    return this.firestore.collection('hospitals').doc(this.userService.getAdditionalData().hospitalId).collection('archivedProjects').add(Project.transformToPlainJsObject(project));
  }

  archiveProject(project: Project) {

    var timeLineEvent = {};
    this.addUserTag(timeLineEvent);

    timeLineEvent['state'] = "ARCHIVED";

    timeLineEvent['time'] = this.datepipe.transform(new Date(), "HHmm");;
    timeLineEvent['date'] = this.datepipe.transform(new Date(), "yyyyMMdd");
    var dateString = this.datepipe.transform(new Date(), "yyyyMMddHHmmss");
    project.timeline[dateString] = timeLineEvent;


    const index = project.resellers.indexOf(this.userService.getAdditionalData().resellerId, 0);
    if (index > -1) {
      project.resellers.splice(index, 1);
    }
    if (project.finishedResellers == null) {
      project.finishedResellers = [];
    }
    project.finishedResellers.push(this.userService.getAdditionalData().resellerId);

    this.firestore.collection('projects').doc(project.projectKey).update({ resellers: project.resellers, finishedResellers: project.finishedResellers });

    Object.keys(project).forEach(key => project[key] === undefined && delete project[key]);
    return this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).collection('archivedProjects').add(Project.transformToPlainJsObject(project));
  }

  async createApplication(projectDetail, selectedDates, userKey, user, selectedSubPositionNumber) {

    Object.keys(projectDetail).forEach(key => projectDetail[key] === undefined && delete projectDetail[key]);
    var timeLineEvents = {};

    var timeLineEvent = {};
    this.addUserTag(timeLineEvent);

    timeLineEvent['state'] = "ASSIGNED_BY_DISPO";

    timeLineEvent['time'] = this.datepipe.transform(new Date(), "HHmm");;
    timeLineEvent['date'] = this.datepipe.transform(new Date(), "yyyyMMdd");
    var dateString = this.datepipe.transform(new Date(), "yyyyMMddHHmm");

    timeLineEvents[dateString] = timeLineEvent;

    if (projectDetail.newApplications == null) {
      projectDetail.newApplications = new Array<any>();
    }
    projectDetail.newApplications.push(userKey);

    var selected = selectedDates.map(ele => {
      return { "date": this.datepipe.transform(ele, "yyyyMMdd"), 'state': 'ASSIGNED_BY_DISPO', 'positionNumber': selectedSubPositionNumber };
    });

    this.firestore
      .collection('projects')
      .doc(projectDetail.projectKey)
      .collection("applications")
      .doc(userKey)
      .set({
        "user": user,
        "state": "ASSIGNED_BY_DISPO",
        "timeLineEvents": timeLineEvents,
        "dates": selected,
        "selectedSubPositionNumber": selectedSubPositionNumber
      });

    var appProject = Object.assign({}, projectDetail);
    // appProject['state'] = 'HAS_APPLICATIONS';

    this.firestore
      .collection('users')
      .doc(userKey)
      .collection('appliedProjects')
      .doc(projectDetail.projectKey)
      .set(appProject);

    // Send notification to User
    var msg = new Message();
    msg.title = "Zugewiesen";
    msg.type = "SUGGESTION";
    msg.link = projectDetail.projectKey;
    msg.text = "Ihr Disponent hat Ihnen einen Einsatz zugewiesen. Bitte bestätigen Sie diesen oder lehnen Sie ihn ab.";
    msg.senderId = this.userService.getUser().uid;
    msg.recipientId = userKey;
    msg.receiverName = user['name'];
    msg.dateAdded = new Date().getTime();
    msg.senderName = this.userService.getAdditionalData().name;
    this.addMessage(msg);
  }


  confirmJob(project, appliant, shouldupdateState) {
    if (shouldupdateState) {

      var timeLineEvent = {};
      this.addUserTag(timeLineEvent);

      timeLineEvent['state'] = "DISTRIBUTED";

      timeLineEvent['time'] = this.datepipe.transform(new Date(), "HHmm");;
      timeLineEvent['date'] = this.datepipe.transform(new Date(), "yyyyMMdd");;
      var dateString = this.datepipe.transform(new Date(), "yyyyMMddHHmmss");
      project.timeline[dateString] = timeLineEvent;

      this.firestore.collection('projects').doc(project.projectKey).update({ "currentlyInZafRelevantState": firebase.firestore.FieldValue.increment(1), "currentlyInHospitalRelevantState": firebase.firestore.FieldValue.increment(-1), dayArrays: project.dayArrays, state: "DISTRIBUTED", timeline: project.timeline, acceptsApplications: false });
    } else {
      this.firestore.collection('projects').doc(project.projectKey).update({ "currentlyInZafRelevantState": firebase.firestore.FieldValue.increment(1), "currentlyInHospitalRelevantState": firebase.firestore.FieldValue.increment(-1), dayArrays: project.dayArrays, timeline: project.timeline });
    }
    this.firestore.collection('projects').doc(project.projectKey).collection('applications').doc(appliant.key).update(appliant);
  }


  removeUserFromNewApplications(project, userKey) {
    var newApplicationArray = new Array<String>();
    if (project.newApplications != null) {
      project.newApplications.forEach(element => {
        if (element != userKey) {
          newApplicationArray.push(element);
        }
      });

    }
    this.firestore.collection('projects').doc(project.projectKey).update({ "newApplications": newApplicationArray });
  }

  distributeJobPartially(project: import("../../projects/projects/project.model").Project, appliant: any) {

    project = Project.transformToPlainJsObject(project);
    Object.keys(project).forEach(key => project[key] === undefined && delete project[key]);

    this.firestore.collection('projects').doc(project.projectKey).update({ "currentlyInZafRelevantState": firebase.firestore.FieldValue.increment(1), "currentlyInHospitalRelevantState": firebase.firestore.FieldValue.increment(-1), state: "HAS_APPLICATIONS", dayArrays: project.dayArrays });

    this.firestore.collection('projects').doc(project.projectKey).collection('applications').doc(appliant.key).update(appliant);
  }

  // confirmJob(project: import("../../projects/projects/project.model").Project) {
  //   var timeLineEvent = {};

  //   timeLineEvent['state'] = "CONFIRMED";
  //   timeLineEvent['textParam'] = project.hospital.name;

  //   timeLineEvent['time'] = this.datepipe.transform(new Date(), "HHmm");;
  //   timeLineEvent['date'] = this.datepipe.transform(new Date(), "yyyyMMdd");;
  //   var dateString = this.datepipe.transform(new Date(), "yyyyMMddHHmmss");
  //   project.timeline[dateString] = timeLineEvent;
  //   project = Project.transformToPlainJsObject(project);
  //   Object.keys(project).forEach(key => project[key] === undefined && delete project[key]);

  //   this.firestore.collection('projects').doc(project.projectKey).update({ state: 'CONFIRMED', timeline: project.timeline });
  //   return timeLineEvent;
  // }

  // confirmJobPartially(project: import("../../projects/projects/project.model").Project) {
  //   var timeLineEvent = {};

  //   timeLineEvent['state'] = "CONFIRMED";
  //   timeLineEvent['textParam'] = project.hospital.name;

  //   timeLineEvent['time'] = this.datepipe.transform(new Date(), "HHmm");;
  //   timeLineEvent['date'] = this.datepipe.transform(new Date(), "yyyyMMdd");;
  //   var dateString = this.datepipe.transform(new Date(), "yyyyMMddHHmmss");
  //   project.timeline[dateString] = timeLineEvent;
  //   project = Project.transformToPlainJsObject(project);
  //   Object.keys(project).forEach(key => project[key] === undefined && delete project[key]);

  //   var newKey = this.getNewKey();
  //   this.firestore.collection('projects').doc(newKey).set(project);
  //   return timeLineEvent;
  // }

  updateApplication(application, projectKey, newAppl) {
    this.firestore.collection('projects').doc(projectKey).collection('applications').doc(application.key).update(newAppl);
  }

  publishJob(project: import("../../projects/projects/project.model").Project) {
    var timeLineEvent = {};
    this.addUserTag(timeLineEvent);

    timeLineEvent['state'] = "PUBLISHED";

    timeLineEvent['time'] = this.datepipe.transform(new Date(), "HHmm");;
    timeLineEvent['date'] = this.datepipe.transform(new Date(), "yyyyMMdd");;
    var dateString = this.datepipe.transform(new Date(), "yyyyMMddHHmmss");
    project.timeline[dateString] = timeLineEvent;
    project = Project.transformToPlainJsObject(project);
    Object.keys(project).forEach(key => project[key] === undefined && delete project[key]);

    this.firestore.collection('projects').doc(project.projectKey).update({ state: 'PUBLISHED', timeline: project.timeline });
    return timeLineEvent;

  }

  // changeJobToActive(project: Project) {
  //   var timeLineEvent = {};

  //   timeLineEvent['state'] = "ACTIVE";

  //   timeLineEvent['time'] = this.datepipe.transform(new Date(), "HHmm");;
  //   timeLineEvent['date'] = this.datepipe.transform(new Date(), "yyyyMMdd");;
  //   var dateString = this.datepipe.transform(new Date(), "yyyyMMddHHmmss");
  //   project.timeline[dateString] = timeLineEvent;
  //   project = Project.transformToPlainJsObject(project);
  //   Object.keys(project).forEach(key => project[key] === undefined && delete project[key]);

  //   this.firestore.collection('projects').doc(project.projectKey).update({ state: 'ACTIVE', timeline: project.timeline });
  //   return timeLineEvent;
  // }

  updateUserQualifications(userId, selectedQualifications: any) {
    this.firestore.collection('users').doc(userId).update({ qualifications: selectedQualifications });
  }

  async saveHospital(hospital: Hospital, hospitalKey: string, shouldLink) {
    Object.keys(hospital).forEach((key) => (hospital[key] == null) && delete hospital[key]);

    this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).collection('hospitalCityCodes').doc(hospital.cityCode).set({ wasInitialized: false });
    await this.firestore.collection('hospitals').doc(hospitalKey).set(Hospital.transformToPlainJsObject(hospital));
    this.addNewHPUser(hospital.email, hospitalKey, true);
    return hospitalKey;
  }

  updatePublishPreference(checked: any) {
    this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).update({ 'publishAutomatically': checked });
  }

  updatePublishPreferenceForHospital(checked: any) {
    this.firestore.collection('hospitals').doc(this.userService.getAdditionalData().hospitalId).update({ 'publishAutomatically': checked });
  }

  updateESignaturePreference(checked: any) {
    this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).update({ 'alwaysSignWithESignature': checked });
  }

  updateShiftTimesForHospital(times) {
    this.firestore.collection('hospitals').doc(this.userService.getAdditionalData().hospitalId).update(times);
  }

  updateMyZAFSettings(settings) {
    this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).update(settings);
  }

  getPublishPreference() {
    if (this.userService.getAdditionalData().role == 'HP') {
      return this.firestore.collection('hospitals').doc(this.userService.getAdditionalData().hospitalId).get();

    } else {
      return this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).get();
    }
  }

  async saveProject(resellerId, project: import("../../projects/projects/project.model").Project) {

    var timeLineEvent = {};
    this.addUserTag(timeLineEvent);
    if (!project.emergency) {
      if (this.userService.getAdditionalData().role == 'RS') {
        var reseller = await this.firestore.collection('reseller').doc(resellerId).get().toPromise();
        var resellerData = reseller.data();
        if (!resellerData['publishAutomatically']) {
          timeLineEvent['checkBeforePublishing'] = true;
          timeLineEvent['state'] = "NEW";
          project.state = "NEW";
          project.currentlyInZafRelevantState = 1;
          project.currentlyInHospitalRelevantState = 0;
        } else {
          project.currentlyInZafRelevantState = 0;
          project.currentlyInHospitalRelevantState = 1;
          project.state = "PUBLISHED";
          timeLineEvent['state'] = "PUBLISHED";
        }
      } else {
        project.currentlyInZafRelevantState = 0;
        project.currentlyInHospitalRelevantState = 1;
        project.state = "PUBLISHED";
        timeLineEvent['state'] = "PUBLISHED";
      }
    } else {
      timeLineEvent['emergency'] = true;
      project.state = "PUBLISHED";
      timeLineEvent['state'] = "PUBLISHED";
    }

    timeLineEvent['time'] = this.datepipe.transform(new Date(), "HHmm");;
    timeLineEvent['date'] = this.datepipe.transform(new Date(), "yyyyMMdd");;
    var dateString = this.datepipe.transform(new Date(), "yyyyMMddHHmmss");
    project.timeline = {};
    project.timeline[dateString] = timeLineEvent;

    Object.keys(project).forEach(key => project[key] === undefined && delete project[key]);

    var key = this.firestore.createId();
    return await this.firestore.collection('projects').doc(key).set(Project.transformToPlainJsObject(project));
  }

  addNewHPUser(email: string, hospitalKey: string, isAdmin: boolean): String {
    var id = this.firestore.createId();
    if (isAdmin) {
      this.firestore.collection('userInfo').doc(email.split('.').join(',')).set({
        role: "HP", reseller: this.userService.getAdditionalData().resellerId.toString(), resellerId: this.userService.getAdditionalData().resellerId, hospitalId: hospitalKey, permissions: [


          { 'key': 'EDIT_USERS', val: 'Standortmitarbeiter verwalten' },
          { 'key': "INVITE_USERS", val: "Standortmitarbeiter einladen" },
          { 'key': "EDIT_USERS", val: "Standortmitarbeiter verwalten" },
          { 'key': "CHANGE_PERMISSIONS", val: "Standortmitarbeiter Berechtigungen verwalten" },
          { 'key': "INVITE_ZA", val: "Zeitarbeiter einladen" },
          { 'key': "EDIT_ZA", val: "Zeitarbeiter verwalten" },
          { 'key': "MANAGE_DOCUMENTS", val: "AÜV" },
          { 'key': "MANAGE_PROJECTS", val: "Projekte verwalten" }

        ]
      });

    } else {
      this.firestore.collection('userInfo').doc(email.split('.').join(',')).set({ role: "HP", reseller: this.userService.getAdditionalData().resellerId.toString(), resellerId: this.userService.getAdditionalData().resellerId, hospitalId: hospitalKey });
    }
    return id;
  }

  updateZaUser(userKey, values) {
    this.firestore.collection('users').doc(userKey).update(values);
  }

  updateInternalUser(userKey, values) {
    this.firestore.collection('users').doc(userKey).update(values);
  }

  addNewZAUserCode(userKey, values): String {
    values['reseller'] = this.userService.getAdditionalData().resellerId.toString();
    values['resellerId'] = this.userService.getAdditionalData().resellerId.toString();
    values['role'] = "ZA";
    this.firestore.collection('userInfo').doc(values.email.split('.').join(',')).set(values);
    return userKey;
  }

  addNewInternalUser(userKey, values): String {
    values['hospitalId'] = this.userService.getAdditionalData().hospitalId.toString();
    values['role'] = "INTUSER";
    this.firestore.collection('userInfo').doc(values.email.split('.').join(',')).set(values);
    return userKey;
  }


  addNewZAFUserCode(email: any, permissions: Array<any>): String {
    var id = this.firestore.createId();
    this.firestore.collection('userInfo').doc(email.split('.').join(',')).set({ role: "ZAF", reseller: this.userService.getAdditionalData().resellerId.toString(), permissions: permissions });
    return id;
  }

  getMessagesForCurrentUser() {
    var currentUserId = this.userService.getUser().uid;
    return this.firestore.collection('messages', ref => ref.where("recipientId", "==", currentUserId).orderBy("dateAdded").limitToLast(4)).get();
  }

  getMessagingPartnersForUser() {
    var currentUserId = this.userService.getUser().uid;
    return this.firestore.collection('users').doc(currentUserId).collection('chats').get();
  }

  getMessagesWithPartnerForUser(partnerId) {
    var currentUserId = this.userService.getUser().uid;
    return this.firestore.collection('users').doc(currentUserId).collection('chats').doc(partnerId).collection('messages', ref => ref.orderBy("dateAdded")).get();
  }

  getDistributionStatistics() {

    return this.firestore.collection('hospitals').doc(this.userService.getAdditionalData().hospitalId).collection('statistics').doc('distributedProjectCount').get();
  }

  getDistributionStatisticsForReseller() {
    return this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).collection('statistics').doc('distributedProjectCount').get();

  }

  saveDepartment(val) {
    var hospitalId = this.userService.getAdditionalData().hospitalId;
    this.firestore.collection('hospitals').doc(hospitalId).collection('departments').add(val);
  }

  updateDepartmentShift(departmentId, val) {
    var hospitalId = this.userService.getAdditionalData().hospitalId;
    this.firestore.collection('hospitals').doc(hospitalId).collection('departments').doc(departmentId).update({ 'shift': val });
  }

  deleteDepartment(id) {
    var hospitalId = this.userService.getAdditionalData().hospitalId;
    this.firestore.collection('hospitals').doc(hospitalId).collection('departments').doc(id).delete();
  }

  saveShift(shift) {
    var hospitalId = this.userService.getAdditionalData().hospitalId;
    this.firestore.collection('hospitals').doc(hospitalId).collection('shifts').add(shift);
  }

  deleteShift(id) {
    var hospitalId = this.userService.getAdditionalData().hospitalId;
    this.firestore.collection('hospitals').doc(hospitalId).collection('shifts').doc(id).delete();

  }

  getAllShiftsForHospital() {
    var hospitalId = this.userService.getAdditionalData().hospitalId;
    return this.firestore.collection('hospitals').doc(hospitalId).collection('shifts').get();
  }

  getAllDepartmentsForHospital() {
    var hospitalId = this.userService.getAdditionalData().hospitalId;
    return this.firestore.collection('hospitals').doc(hospitalId).collection('departments').get();
  }

  getAllProjectsForHospital(hospitalId: string, withState?: string, internal?: boolean): Observable<any> {
    if (internal) {
      if (withState == "PUBLISHED") {
        return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().hospitalId.toString()).where("internal", "==", true).where("state", "in", ['PUBLISHED', 'NEW'])).get();
      }
      if (withState == "APPLIED") {
        return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().hospitalId.toString()).where("internal", "==", true).where("state", "in", ['APPLIED', 'HAS_APPLICATIONS'])).get();
      }
      if (withState == "DISTRIBUTED") {
        return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().hospitalId.toString()).where("internal", "==", true).where("state", "==", 'DISTRIBUTED')).get();
      }
      if (withState == "FINISHED") {
        return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().hospitalId.toString()).where("internal", "==", true).where("state", "==", 'FINISHED')).get();
      }
      if (withState == "CLOSED") {
        return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().hospitalId.toString()).where("internal", "==", true).where("state", "==", 'CLOSED')).get();
      }
      if (withState == "ARCHIVED") {
        return this.firestore.collection('hospitals').doc(this.userService.getAdditionalData().hospitalId).collection('archivedProjects').get();
      }
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().hospitalId.toString()).where("internal", "==", '0').where("hospital.id", "==", hospitalId)).get();

    } else {
      if (withState == "PUBLISHED") {
        return this.firestore.collection('projects', ref => ref.where("hospital.id", "==", this.userService.getAdditionalData().hospitalId.toString()).where("internal", "==", false).where("state", "in", ['PUBLISHED', 'NEW'])).get();
      }
      if (withState == "APPLIED") {
        return this.firestore.collection('projects', ref => ref.where("hospital.id", "==", this.userService.getAdditionalData().hospitalId.toString()).where("internal", "==", false).where("state", "in", ['APPLIED', 'HAS_APPLICATIONS'])).get();
      }
      if (withState == "DISTRIBUTED") {
        return this.firestore.collection('projects', ref => ref.where("hospital.id", "==", this.userService.getAdditionalData().hospitalId.toString()).where("internal", "==", false).where("state", "==", 'DISTRIBUTED')).get();
      }
      if (withState == "FINISHED") {
        return this.firestore.collection('projects', ref => ref.where("hospital.id", "==", this.userService.getAdditionalData().hospitalId.toString()).where("internal", "==", false).where("state", "==", 'FINISHED')).get();
      }
      if (withState == "CLOSED") {
        return this.firestore.collection('projects', ref => ref.where("hospital.id", "==", this.userService.getAdditionalData().hospitalId.toString()).where("internal", "==", false).where("state", "==", 'CLOSED')).get();
      }
      if (withState == "ARCHIVED") {
        return this.firestore.collection('hospitals').doc(this.userService.getAdditionalData().hospitalId).collection('archivedProjects').get();
      }
      return this.firestore.collection('projects', ref => ref.where("hospital.id", "==", this.userService.getAdditionalData().hospitalId.toString()).where("internal", "==", false).where("hospital.id", "==", hospitalId)).get();

    }

  }

  getAllProjectsForHospitalAndResellerForCumulatedState(hospitalId: string, withState: string): Observable<any> {
    if (withState == 'NEW') {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where("hospital.id", "==", hospitalId).where("state", "in", ['NEW', 'PUBLISHED', 'APPLIED'])).get();
    } else if (withState == 'DISTRIBUTED') {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where("hospital.id", "==", hospitalId).where("state", "in", ['DISTRIBUTED'])).get();
    } else if (withState == 'FINISHED') {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where("hospital.id", "==", hospitalId).where("state", "in", ['FINISHED', 'CLOSED'])).get();
    }
    return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where("hospital.id", "==", hospitalId).where("state", "==", withState)).get();
  }

  getFiveRelevantProjects() {
    switch (this.userService.getAdditionalData().role) {
      case "RS":
        return this.firestore.collection('projects', ref => ref.where("currentlyInZafRelevantState", ">=", 1).where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).limit(5)).get();
      case 'HP':
        return this.firestore.collection('projects', ref => ref.where("currentlyInHospitalRelevantState", ">=", 1).where("hospital.id", "==", this.userService.getAdditionalData().hospitalId.toString()).limit(5)).get();
    }
  }

  getAllProjectsForResellerUser(withState?: string): Observable<any> {
    var cityCodesForuser = ['None'];
    if (this.userService.getAdditionalData().assignedCityCodes != null) {
      cityCodesForuser = this.userService.getAdditionalData().assignedCityCodes;
    }
    if (withState == null) {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where('hospital.cityCode', "in", cityCodesForuser)).get();
    }
    if (withState == "NEW") {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where('hospital.cityCode', "in", cityCodesForuser).where("state", "==", 'NEW')).get();
    }
    if (withState == "PUBLISHED") {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where('hospital.cityCode', "in", cityCodesForuser)).get();
    }
    if (withState == "DISTRIBUTED") {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where('hospital.cityCode', "in", cityCodesForuser).where("state", "==", 'DISTRIBUTED')).get();
    }
    if (withState == "FINISHED") {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where('hospital.cityCode', "in", cityCodesForuser)).get();
    }
    if (withState == "ARCHIVED") {
      return this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).collection('archivedProjects').get();
    }
    return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where('hospital.cityCode', "in", cityCodesForuser)).get();
  }

  getAllProjectsForReseller(withState?: string): Observable<any> {
    if (withState == null) {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString())).get();
    }
    if (withState == "NEW") {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where("state", "==", 'NEW')).get();
    }

    if (withState == "PUBLISHED") {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where("state", "in", ['PUBLISHED', 'APPLIED', 'HAS_APPLICATIONS'])).get();
    }
    if (withState == "DISTRIBUTED") {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where("state", "==", 'DISTRIBUTED')).get();
    }
    if (withState == "FINISHED") {
      return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where("state", "in", ['FINISHED', 'ARCHIVED'])).get();
    }
    if (withState == "ARCHIVED") {
      return this.firestore.collection('reseller').doc(this.userService.getAdditionalData().resellerId).collection('archivedProjects').get();
    }
    return this.firestore.collection('projects', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString())).get();
  }

  getAllHospitalsWithoutTestMode(): Observable<any> {
    var doc = this.firestore.collection('hospitals', ref => ref.where('testMode', "==", false));
    return doc.get();
  }

  getAllHospitals(): Observable<any> {
    var doc = this.firestore.collection('hospitals');
    return doc.get();
  }

  getAllHospitalsForReseller(): Observable<any> {
    var doc = this.firestore.collection('hospitals', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()));
    return doc.get();
    // if (this.userService.getAdditionalData().role == "ADMIN") {
    //   return this.firebaseDatabase.list('hospitals').snapshotChanges();
    // } else {
    // return this.firebaseDatabase.list('reseller/' + this.userService.getAdditionalData().resellerId.toString() + '/hospitals').snapshotChanges();
    // }
  }

  getResellersForHospital() {
    var doc = this.firestore.collection('reseller', ref => ref.where("hospitals", "array-contains", this.userService.getAdditionalData().hospitalId));
    return doc.get();
  }

  getAllHospitalsForResellerUser(): Observable<any> {
    var cityCodesForuser = ['None'];
    if (this.userService.getAdditionalData().assignedCityCodes != null) {
      var cityCodesForuser = this.userService.getAdditionalData().assignedCityCodes;
    }
    var doc = this.firestore.collection('hospitals', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).where('cityCode', "in", cityCodesForuser));
    return doc.get();
    // if (this.userService.getAdditionalData().role == "ADMIN") {
    //   return this.firebaseDatabase.list('hospitals').snapshotChanges();
    // } else {
    // return this.firebaseDatabase.list('reseller/' + this.userService.getAdditionalData().resellerId.toString() + '/hospitals').snapshotChanges();
    // }
  }


  getFiveHospitalsForReseller(): Observable<any> {
    var doc = this.firestore.collection('hospitals', ref => ref.where("resellers", "array-contains", this.userService.getAdditionalData().resellerId.toString()).limit(5));
    return doc.get();
  }

  getAllDepartmentOptions(companyType?) {
    if (companyType) {
      return this.firestore.collection('departments-' + companyType, ref => ref.orderBy('name')).get();
    } else {
      return this.firestore.collection('departments', ref => ref.orderBy('name')).get();
    }
  }

  getAdditionalUserData() {
    if (this.userService.getUser().user == null) {
      return this.firestore.collection('users').doc(this.userService.getUser().uid).get();
    } else {

      return this.firestore.collection('users').doc(this.userService.getUser().user.uid).get();
    }
  }

  async initNewUser(user, userName) {

    var email = user['email'].split('.').join(',');
    var userInfo = await this.firestore.collection('userInfo').doc(email).get().toPromise();
    var a = userInfo.data();
    a['name'] = userName;
    await this.firestore.collection('users').doc(user.uid).set(a);
    await this.firestore.collection('userInfo').doc(email).delete();
    return true;
  }

  getUsers(offset, startKey?): Observable<any> {
    if (startKey) {
      return this.firestore.collection('users').get();
      // return this.firebaseDatabase.list('users', ref => ref.orderByKey().startAt(startKey.toString()).limitToFirst(offset + 1)).valueChanges();
    }
    return this.firestore.collection('users').get();
    // return this.firebaseDatabase.list('users', ref => ref.orderByKey().limitToFirst(offset + 1)).valueChanges();
  }

  getUsersForResellerId(resellerId, role?): Observable<any> {
    return this.firestore.collection('users', ref => ref.where("resellerId", "==", resellerId).where("role", "==", role)).get();
  }

  getHospitalCityCodesForReseller(resellerId) {
    return this.firestore.collection('reseller').doc(resellerId).collection('hospitalCityCodes').get();
  }

  async getAdditionalUserDataForUid(uid) {
    return this.firestore.collection('users').doc(uid).get();
  }

  getAllInternalUsersForHospital() {
    return this.firestore.collection('users', ref => ref.where("role", "==", "INTUSER").where("hospitalId", "==", this.userService.getAdditionalData().hospitalId)).get();
  }

  getAllInvitedIntUsersForHospital(): Observable<any> {
    return this.firestore.collection('userInfo', ref => ref.where("role", "==", "INTUSER").where("hospitalId", "==", this.userService.getAdditionalData().hospitalId)).get();
  }

  getAllZAForReseller(): Observable<any> {
    return this.firestore.collection('users', ref => ref.where("role", "==", "ZA").where("resellerId", "==", this.userService.getAdditionalData().resellerId)).get();
  }

  getAllInvitedZaForReseller(): Observable<any> {
    return this.firestore.collection('userInfo', ref => ref.where("role", "==", "ZA").where("resellerId", "==", this.userService.getAdditionalData().resellerId)).get();
  }

  getFiveZAForReseller(): Observable<any> {
    return this.firestore.collection('users', ref => ref.where("role", "==", "ZA").where("resellerId", "==", this.userService.getAdditionalData().resellerId).limit(5)).get();
  }

  getFiveInternalUsersForHospital(): Observable<any> {
    return this.firestore.collection('users', ref => ref.where("role", "==", "INTUSER").where("hospitalId", "==", this.userService.getAdditionalData().hospitalId).limit(5)).get();

  }

  getFiveExternalUsersForHospital(): Observable<any> {
    var today = new Date();
    var todayString = this.datepipe.transform(today, "yyyyMMdd");
    return this.firestore.collection('hospitals').doc(this.userService.getAdditionalData().hospitalId).collection('dayWorkerMapping').doc(todayString).collection('workers').get();
  }

  getAllInvitedUsersForReseller(): Observable<any> {
    return this.firestore.collection('userInfo', ref => ref.where("role", "==", "RS").where("resellerId", "==", this.userService.getAdditionalData().resellerId)).get();
  }

  getAllUsersForReseller(): Observable<any> {
    return this.firestore.collection('users', ref => ref.where("role", "==", "RS").where("resellerId", "==", this.userService.getAdditionalData().resellerId)).get();
  }

  getAllUsersForHospital(): Observable<any> {
    return this.firestore.collection('users', ref => ref.where("role", "==", "HP").where("hospitalId", "==", this.userService.getAdditionalData().hospitalId)).get();
  }

  getAllInvitedUsersForHospital(): Observable<any> {
    return this.firestore.collection('usersInfo', ref => ref.where("role", "==", "HP").where("hospitalId", "==", this.userService.getAdditionalData().hospitalId)).get();
  }


  getUserData(userId) {
    return this.firestore.collection('users').doc(userId).get();
  }
}
