import { Component, OnInit, Input, SimpleChange, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from 'app/shared/services/firebase.service';
import { UploadService } from 'app/shared/services/upload.service';
import { UserService } from 'app/shared/services/user.service';
import { MatDialogConfig } from '@angular/material';
import { DocusignemailsComponent } from '../docusignemails/docusignemails.component';
import { DatePipe } from '@angular/common';
import { Message } from 'app/models/message';

@Component({
  selector: 'app-change-project-state-pop-up',
  templateUrl: './change-project-state-pop-up.component.html',
  styleUrls: ['./change-project-state-pop-up.component.scss']
})
export class ChangeProjectStatePopUpComponent implements OnInit, OnChanges {
  @Input() public role: string;
  @Input() public state: string;
  @Input() public project;
  @Input() public appl;
  @Input() public currentSubJobCount;
  @Input() public forceEAUV;

  step = 0;
  eauv = false;
  hasEAUVModule = false;
  stateToSet: string;

  shouldShowAuVCreatedButton = false;
  shouldShowAuVSentForResellerButton = false;
  shouldShowAuVSentForHospitalButton = false;
  shouldShowAuVSignedButton = false;

  ModalTitle = '';
  constructor(
    public activeModal: NgbActiveModal,
    private firebaseService: FirebaseService,
    private uploadService: UploadService,
    private userService: UserService,
    public datepipe: DatePipe,
  ) {

  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.shouldShowAuVCreatedButton = this.role == 'RS' && (this.state == 'CONFIRMED_BY_HOSPITAL');
    this.shouldShowAuVSentForResellerButton = this.role == 'RS' && (this.state == 'CONFIRMED_BY_HOSPITAL' || this.state == 'CONTRACTUPLOADED');
    this.shouldShowAuVSentForHospitalButton = this.role == 'HP' && (this.state == 'CONFIRMED_BY_HOSPITAL' || this.state == 'CONTRACTUPLOADED' || this.state == 'CONTRACTTRANSMITTED');
    this.shouldShowAuVSignedButton = this.role == 'RS' && (this.state == 'CONFIRMED_BY_HOSPITAL' || this.state == 'CONTRACTUPLOADED' || this.state == 'CONTRACTTRANSMITTED' || this.state == 'CONTRACTSIGNED');
  }

  ngOnInit() {
    this.asyncInit();
  }

  async asyncInit() {
    if (this.role == 'RS') {
      this.hasEAUVModule = (await this.firebaseService.getResellerData()).data()['eauv'];
      if (!this.hasEAUVModule || this.state != 'CONFIRMED_BY_HOSPITAL') {
        this.step = 1;
      } else if (this.hasEAUVModule && this.forceEAUV) {
        this.eauv = true;
        this.step = 1;
      }
    } else {
      this.step = 1;
    }
    this.shouldShowAuVCreatedButton = this.role == 'RS' && (this.state == 'CONFIRMED_BY_HOSPITAL');
    this.shouldShowAuVSentForResellerButton = this.role == 'RS' && (this.state == 'CONFIRMED_BY_HOSPITAL' || this.state == 'CONTRACTUPLOADED');
    this.shouldShowAuVSentForHospitalButton = this.role == 'HP' && (this.state == 'CONFIRMED_BY_HOSPITAL' || this.state == 'CONTRACTUPLOADED' || this.state == 'CONTRACTTRANSMITTED');
    this.shouldShowAuVSignedButton = this.role == 'RS' && (this.state == 'CONFIRMED_BY_HOSPITAL' || this.state == 'CONTRACTUPLOADED' || this.state == 'CONTRACTTRANSMITTED' || this.state == 'CONTRACTSIGNED');
  }

  close() {
    this.activeModal.dismiss();
  }

  fireAuVCreated() {
    this.stateToSet = 'CONTRACTUPLOADED';
    this.step = 2;
  }

  fireAuVSentByReseller() {
    this.stateToSet = 'CONTRACTTRANSMITTED';
    this.step = 2;
  }

  fireAuVSentByHospital() {
    this.stateToSet = 'CONTRACTSIGNED';
    this.step = 2;
  }

  fireAuvSigned() {
    this.stateToSet = 'CONTRACTCOMPLETED';
    this.step = 2;
  }

  async uploadFileWithoutEAUV(event) {
    console.log(this.stateToSet);
    switch (this.stateToSet) {
      case 'CONTRACTUPLOADED':
        this.uploadService.uploadUnsignedContract(this.appl.user.resellerId, this.project.projectKey, this.appl.key, event.target.files[0]);
        break;
      case 'CONTRACTTRANSMITTED':
        this.uploadService.uploadUnsignedContract(this.appl.user.resellerId, this.project.projectKey, this.appl.key, event.target.files[0]);
        break;
      case 'CONTRACTSIGNED':
        this.uploadService.uploadSignedContract(this.appl.user.resellerId, this.project.projectKey, this.appl.key, event.target.files[0]);
        break;
      case 'CONTRACTCOMPLETED':
        this.uploadService.uploadSignedContract(this.appl.user.resellerId, this.project.projectKey, this.appl.key, event.target.files[0]);
        break;
    }
    await this.updateApplictaion();
    this.activeModal.close({ returnType: "standard" });
  }

  parseDate(dateString, timeString, addAdditionalPreviousWeek?): Date {
    var y = dateString.substr(0, 4);
    var m = dateString.substr(4, 2) - 1;

    var d = dateString.substr(6, 2);
    var hour = 0;
    var min = 0;
    if (timeString != null && timeString != "") {

      hour = timeString.substr(0, 2);
      min = timeString.substr(3, 2);

    }
    // var y = str.substr(0, 4),
    //   m = str.substr(4, 2) - 1,
    //   d = str.substr(6, 2);
    var D = new Date(y, m, d, hour, min);

    if (addAdditionalPreviousWeek) {
      var date = new Date(D.valueOf());
      date.setDate(date.getDate() - 7);
      return date;
    }
    return D;
  }

  async updateApplictaion() {
    var confirmedDates = [];
    this.project.dayArrays[this.currentSubJobCount].forEach(day => {
      if (day['user'] == this.appl.key) {
        day['state'] = this.stateToSet;
        confirmedDates.push(day['date']);
      }
    });

    this.appl['dates'].forEach(dateElement => {
      dateElement['state'] = this.stateToSet;
    });

    var timeLineEvent = {};
    timeLineEvent['user'] = this.userService.getAdditionalData().name;

    timeLineEvent['state'] = this.stateToSet;

    timeLineEvent['time'] = this.datepipe.transform(new Date(), "HHmm");;
    timeLineEvent['date'] = this.datepipe.transform(new Date(), "yyyyMMdd");;
    var dateString = this.datepipe.transform(new Date(), "yyyyMMddHHmmss");

    if (this.appl['timeLineEvents'] == null) {
      this.appl['timeLineEvents'] = {};
    }
    this.appl.state = this.stateToSet;
    this.appl['timeLineEvents'][dateString] = timeLineEvent;

    if (this.stateToSet == 'CONTRACTSIGNED' || this.stateToSet == 'CONTRACTCOMPLETED') {
      this.sendConfirmationMessage(this.appl.key, confirmedDates, this.project.hospital.name);
      var foundOpenPos = false;
      for (var key in this.project.dayArrays) {
        if (this.project.dayArrays.hasOwnProperty(key)) {
          var dayArray = this.project.dayArrays[key];
          dayArray.forEach(element => {
            if (element['state'].toLowerCase() == 'open') {
              foundOpenPos = true;
            }
          });
        }
      }
      console.log(this.appl.user.resellerId);
      return this.firebaseService.changeToConfirmed(this.project, this.appl, this.appl.key, this.currentSubJobCount, confirmedDates, !foundOpenPos, this.appl.user.resellerId);
    } else {
      return this.firebaseService.changeToContractTransmitted(this.project, this.appl, this.appl.key, this.currentSubJobCount);
    }
  }

  uploadFileToIDNOW(event) {
    this.uploadService.uploadUnsignedContract(this.appl.user.resellerId, this.project.projectKey, this.appl.key, event.target.files[0]);
    this.activeModal.close({ returnType: "eauv", file: event.target.files[0] });
  }


  sendConfirmationMessage(userId, confirmedDates, hospitalName) {
    confirmedDates.sort((a, b) => {
      return a > b;
    });
    var msg = new Message();
    msg.title = "Bewerbung bestätigt!";
    msg.text = "Ihre Bewerbung für den Zeitraum zwischen " + this.datepipe.transform(this.parseDate(confirmedDates[0], null), "dd.MM.yyyy") + " und " + this.datepipe.transform(this.parseDate(confirmedDates[confirmedDates.length - 1], null), "dd.MM.yyyy") + " bei " + hospitalName + " wurde angenommen.";
    msg.senderId = this.userService.getUser().uid;
    msg.recipientId = userId;
    msg.receiverName = "";
    msg.dateAdded = new Date().getTime();
    msg.senderName = "SHYFT";
    this.firebaseService.addMessage(msg);
  }
}
