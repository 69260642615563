import { Hospital } from 'app/hospitals/hospitals/hospital.model';

export class Company {
  static tansformToPlainJsObject(company: Company) {
      return Object.assign({}, company);
  }

    public companyName: string;
    public companyAddress: string;

    constructor(companyName?: string, companyAddress?: string);
    constructor(companyName: string, companyAddress: string) {
        this.companyName = companyName;
        this.companyAddress = companyAddress;
    }

    static fromJson(arg0: any): Company {
        var returnCompany = new Company();
        returnCompany.companyName = arg0['companyName'];
        returnCompany.companyAddress = arg0['companyAddress'];

        return returnCompany;
    }
}
