import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'app/shared/services/user.service';
import { GeocodingApiService } from 'app/shared/services/geocoding-api-service.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from 'app/shared/services/firebase.service';
import { isThisSecond } from 'date-fns';

@Component({
  selector: 'app-adduserforcitycode',
  templateUrl: './adduserforcitycode.component.html',
  styleUrls: ['./adduserforcitycode.component.scss']
})
export class AdduserforcitycodeComponent implements OnInit {
  @Input() public cityCode;

  updated = false;
  selectedDates = Array<any>();

  possibleDays = Array<any>();
  possibleUsers = Array<any>();
  selectedUser: any;
  selected = 'option2';

  ModalTitle = '';
  constructor(private userService: UserService,
    private geocodingApiService: GeocodingApiService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseService
  ) {

  }

  ngOnInit() {
    this.getPossibleUsers();
  }

  close() {
    this.activeModal.dismiss();
  }

  userChanged(user) {
    this.selectedUser = user;
  }

  async getPossibleUsers() {
    var snap = await this.firebaseService.getAllUsersForReseller().toPromise();
    snap.forEach(async user => {
      var data = user.data();
      data['id'] = user.id;
      this.possibleUsers.push(data);
    });
  }

  save() {
    var cityCodes = [];
    if (this.selectedUser['assignedCityCodes'] != null) {
      cityCodes = this.selectedUser['assignedCityCodes'];
    }
    if (!cityCodes.includes(this.cityCode)) {
      cityCodes.push(this.cityCode);

    }
    this.firebaseService.updateCityCodesForUser(this.selectedUser.id, cityCodes);
    this.activeModal.close();
  }
}
