import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'app/shared/services/user.service';
import { GeocodingApiService } from 'app/shared/services/geocoding-api-service.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseService } from 'app/shared/services/firebase.service';

@Component({
  selector: 'app-docusignemails',
  templateUrl: './docusignemails.component.html',
  styleUrls: ['./docusignemails.component.scss']
})
export class DocusignemailsComponent implements OnInit {
  myForm: FormGroup;

  ModalTitle = '';
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      resellerName: "",
      resellerEmail: "",
      hospitalName: "",
      hospitalEmail: ""      
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  save() {
    var values = this.myForm.value;
    this.activeModal.close(values);
  }
}
