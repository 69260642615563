import { data } from 'app/shared/data/smart-data-table';
import { Project } from 'app/projects/projects/project.model';

export class AdditionalUserData {
    hospitalId: string;
    address: string;
    resellerId: string;
    companyId: string;
    city: string;
    cityCode: string;
    country: string;
    assignedCityCodes: Array<string>;
    email: string;
    name: string;
    occupations: Array<string>;
    resellers: Array<string>;
    fcmTokens: any;
    street: string;
    finishedProjects: any;
    permissions: any;
    activeProjects: any;
    idNowAccountCreated: boolean;
    role: string;
    station: string;

    public static fromJson(json): AdditionalUserData {
        if (!json) {
            return new AdditionalUserData();
        }
        var additionalData = new AdditionalUserData();
        additionalData.station = json.station;
        additionalData.address = json.address;
        additionalData.city = json.city;
        additionalData.idNowAccountCreated = json.idNowAccountCreated;
        additionalData.assignedCityCodes = json.assignedCityCodes;
        additionalData.permissions = json.permissions;
        additionalData.cityCode = json.cityCode;
        additionalData.country = json.country;
        additionalData.email = json.email;
        additionalData.name = json.name;
        additionalData.fcmTokens = json.fcmTokens;
        additionalData.street = json.street;
        additionalData.resellerId = json.resellerId;
        additionalData.role = json.role;

        var occupations = Array<string>();
        for (var val in json.occupations) {
            occupations.push(val);
        }
        additionalData.occupations = occupations;

        var finishedProjects = {};
        for (var val in json.finishedProjects) {
            finishedProjects['fdsf'] = (Project.fromJson(val));
        }
        additionalData.finishedProjects = finishedProjects;
        additionalData.hospitalId = json.hospitalId;


        var resellers = Array<string>();
        for (var val in json.resellers) {
            resellers.push(val);
        }
        additionalData.resellers = resellers;

        additionalData.companyId = json.companyId;
        return additionalData;
    }

    public toJson() {
        var values = {};
        values['station'] = this.station;
        values['hospitalId'] = this.hospitalId;
        values['permissions'] = this.permissions;
        values['idNowAccountCreated'] = this.idNowAccountCreated;
        values['companyId'] = this.companyId;
        values['address'] = this.address;
        values['assignedCityCodes'] = this.assignedCityCodes;
        values['fcmTokens'] = this.fcmTokens;
        values['role'] = this.role;
        values['city'] = this.city;
        values['cityCode'] = this.cityCode;
        values['country'] = this.country;
        values['email'] = this.email;
        values['name'] = this.name;
        values['street'] = this.street;
        values['resellerId'] = this.resellerId;
        return values;
    }
}