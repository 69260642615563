import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styles: []
})
export class ApplyComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal, ) { }

  ngOnInit() {
  }

  saveApplication() {
    this.activeModal.close();
  }

}
