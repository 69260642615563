import { Component, OnInit, Input } from '@angular/core';
import { Qualifications } from 'app/projects/projects/qualifications';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Message } from 'app/models/message';
import { UserService } from 'app/shared/services/user.service';
import { FirebaseService } from 'app/shared/services/firebase.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() public user;

  ModalTitle = '';
  myForm: FormGroup;
  constructor(private userService: UserService,
      public activeModal: NgbActiveModal,
      private formBuilder: FormBuilder,
      private firebaseService: FirebaseService
  ) {

  }

  ngOnInit() {
      this.buildItemForm();
      this.ModalTitle = "Nachricht an " + this.user.name;
  }

  private buildItemForm() {
      this.myForm = this.formBuilder.group({
        message: ["" , Validators.required],
        title: ["" , Validators.required]
      });
  }

  submitForm() {
      var values = this.myForm.value;
      var msg = new Message();
      msg.title = values.title;
      msg.type = "DM";
      msg.text = values.message;
      msg.senderId = this.userService.getUser().uid;
      msg.recipientId = this.user.id;
      msg.receiverName = this.user.name;
      msg.dateAdded = new Date().getTime();
      msg.senderName = this.userService.getAdditionalData().name;
      this.firebaseService.addMessage(msg);
      this.activeModal.close(values);
  }

  close() {
    this.activeModal.dismiss();
  }
}
