import { Injectable } from '@angular/core';
import { AdditionalUserData } from '../../models/additionalData';
import { BehaviorSubject } from 'rxjs';
import { FirebaseService } from './firebase.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user;
  private additionalData: AdditionalUserData;
  private projectsNeedToBeConfirmed;
  private shouldSignWithESignature;
  private reseller;
  public currentUser: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor() { }

  setUser(user) {
    this.user = user;
  }

  getUser() {
    return this.user;
  }

  async loadUserData() {

  }

  getAdditionalData() {
    var localStorageUser = JSON.parse(localStorage.getItem('userData'));

    if (this.projectsNeedToBeConfirmed == null) {
      this.projectsNeedToBeConfirmed = localStorage.getItem('projectsNeedToBeConfirmed') == 'true';
    }
    
    if (this.shouldSignWithESignature == null) {
      this.shouldSignWithESignature = localStorage.getItem('shouldSignWithESignature') == 'true';
    }

    var addData = this.additionalData ? this.additionalData : AdditionalUserData.fromJson(localStorageUser);
    return addData;
  }

  setAdditionalUserData(additionalData: any) {
    localStorage.setItem('userData', JSON.stringify(additionalData));
    this.additionalData = AdditionalUserData.fromJson(additionalData);
    this.currentUser.next({});
  }

  setProjectsNeedToBeConfirmed(needToBeConfirmed) {
    this.projectsNeedToBeConfirmed = needToBeConfirmed;
    if (needToBeConfirmed != null) {
      localStorage.setItem('projectsNeedToBeConfirmed', needToBeConfirmed.toString());
    }
  }

  setAlwaysSignWithESignature(shouldSignWithESignature) {
    this.shouldSignWithESignature = shouldSignWithESignature;
    if (shouldSignWithESignature != null) {
      localStorage.setItem('shouldSignWithESignature', shouldSignWithESignature.toString());
    }
  }

  getAlwaysSignWithESignature(): boolean {
    return this.shouldSignWithESignature != null ? this.shouldSignWithESignature : (this.shouldSignWithESignature = JSON.parse(localStorage.getItem('shouldSignWithESignature')) != null ? JSON.parse(localStorage.getItem('shouldSignWithESignature')) == 'true' : false);
  }

  getProjectsNeedToBeConfirmed(): boolean {
    return this.projectsNeedToBeConfirmed != null ? this.projectsNeedToBeConfirmed : (this.projectsNeedToBeConfirmed = JSON.parse(localStorage.getItem('projectsNeedToBeConfirmed')) != null ? JSON.parse(localStorage.getItem('projectsNeedToBeConfirmed')) == 'true' : false);
  }
}

