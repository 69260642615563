import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserPermissions } from 'app/users/userpermissions';
import { AngularFireStorage } from '@angular/fire/storage';
import { FirebaseService } from 'app/shared/services/firebase.service';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss']
})
export class AddUserComponent implements OnInit {
  possiblePermissions = Array<any>();
  uploadedFile;

  private selectedPermissions = Array<any>();

  ModalTitle = '';
  @Input() data: {};
  myForm: FormGroup;
  constructor(
    private afStorage: AngularFireStorage,
    private firebaseService: FirebaseService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit() {
    const keys = Object.keys(UserPermissions);
    keys.forEach(key => {
      this.possiblePermissions.push({ key: key, val: UserPermissions[key] })
    });
    this.buildItemForm(this.data);
    this.ModalTitle = "Zeitarbeiter hinzufügen";
  }

  private buildItemForm(item) {
    this.myForm = this.formBuilder.group({
      email: [item.email || '', Validators.required],
      name: [item.name || '', Validators.required],
      telephone: [item.telephone || '', Validators.required],
      positionname: [item.positionname || '', Validators.required],
      street: [item.positionname || '', Validators.required],
      city: [item.positionname || '', Validators.required],
      country: [item.positionname || '', Validators.required],
      cityCode: [item.positionname || '', Validators.required]
    });
  }


  changePermission(perm) {
    var alreadyChosen = false;
    var indexOfElement = 0;
    var index = 0;
    this.selectedPermissions.forEach(element => {
      if (element.key == perm.key) {
        alreadyChosen = true;
        indexOfElement = index;
      }
      index++;
    });
    if (!alreadyChosen) {
      this.selectedPermissions.push(perm);
    } else {
      this.selectedPermissions.splice(indexOfElement, 1);
    }
  }

  doesHavePermission(qual) {
    var doesExist = false;
    this.selectedPermissions.forEach(element => {
      if (element.key == qual.key) {
        doesExist = true;
      }
    });
    return doesExist;
  }

  submitForm() {
    var values = this.myForm.value;
    values.permissions = this.selectedPermissions;
    values['address'] = values['street'] + "," + values['cityCode'] + "+" + values['city'] + "," + values['country'];
    this.activeModal.close(values);
    // this.firebaseService.saveZAFUser(values, this.uploadedFile);
  }

  upload(event) {
    this.uploadedFile = event.target.files[0];
  }
}
