import { Hospital } from 'app/hospitals/hospitals/hospital.model';

export class Project {
    static transformToPlainJsObject(project: any): any {
        if (project.hospital != null) {
            project.hospital = Hospital.transformToPlainJsObject(project.hospital);
        }
        if (project.timeline != null) {
            Object.keys(project.timeline).map(function (key, index) {
                project.timeline[key] = Object.assign({}, project.timeline[key])
            });
        }
        if (project.dayArray != null) {
            Object.keys(project.dayArray).map(function (key, index) {
                project.dayArray[key] = Object.assign({}, project.dayArray[key])
            });
        }
        return Object.assign({}, project);
    }

    public info: string;
    public department: string;
    public endDate: string;
    public startDate: string;
    public functionalArea: {};
    public typeOfEmployment: {};
    public hospital: Hospital;

    public currentlyInZafRelevantState: number;
    public currentlyInHospitalRelevantState: number;
    public qualifications: Array<any>;
    public dayArrays: Map<any, any>;
    public experiences: Array<any>;
    public additionalQualifications: Array<any>;
    public shifts: Array<any>;
    public applications: any;
    public hospitalId: string;
    public projectKey: string;
    public resellers: Array<any>;
    public finishedResellers: Array<any>;
    public job: string;
    public state: string;
    public emergency: boolean;
    public onlyLongTerm: boolean;
    public mayBeSplitted: boolean;
    public splitOption: string;
    public station: string;
    public chosenUser: string;
    public acceptsApplications: boolean;
    public chosenUsers: Array<string>;
    public splittedMinDays: string;
    public timeline: {};
    public publishedForEveryone: boolean;
    public internal;
    public lateShiftTimes;
    public nightShiftTimes;
    public earlyShiftTimes;

    setHospital(hospital: Hospital) {
        this.hospital = hospital;
    }

    constructor(earlyShiftTimes?: string,nightShiftTimes?: string,lateShiftTimes?: string, internal? : boolean, publishedForEveryone?: boolean, finishedResellers?: Array<any>, splitOption?: string, acceptsApplications?: boolean, currentlyInHospitalRelevantState?: number, dayArrays?: Map<any, any>, chosenUsers?: Array<string>, timeline?: {}, emergency?: boolean, onlyLongTerm?: boolean, splittedMinDays?: string, mayBeSplitted?: boolean, station?: string, state?: string, resellers?: Array<any>, info?: string, endDate?: string, startDate?: string, functionalArea?: string, typeOfEmployment?: string, department?: string, additionalQualifications?: Array<any>, qualifications?: Array<any>, experiences?: Array<any>, shifts?: Array<any>, applications?: Array<any>, projectKey?: string, hospitalId?: string, job?: string);
    constructor(earlyShiftTimes: string, nightShiftTimes: string, lateShiftTimes: string, internal: boolean, publishedForEveryone: boolean, finishedResellers: Array<any>, splitOption: string, acceptsApplications: boolean, currentlyInHospitalRelevantState: number, dayArrays: Map<any, any>, chosenUsers: Array<string>, timeline: {}, emergency: boolean, onlyLongTerm: boolean, splittedMinDays: string, mayBeSplitted: boolean, station: string, state: string, resellers: Array<any>, info: string, endDate: string, startDate: string, functionalArea: string, typeOfEmployment: string, department: string, additionalQualifications: Array<any>, qualifications: Array<any>, experiences: Array<any>, shifts: Array<any>, applications: Array<any>, projectKey: string, hospitalId: string, job: string) {
        this.info = info;
        this.internal = internal;
        this.lateShiftTimes = lateShiftTimes;
        this.nightShiftTimes = nightShiftTimes;
        this.earlyShiftTimes = earlyShiftTimes;
        this.chosenUsers = chosenUsers;
        this.timeline = timeline;
        this.acceptsApplications = acceptsApplications;
        this.splitOption = splitOption;
        this.department = department;
        this.currentlyInHospitalRelevantState = currentlyInHospitalRelevantState;
        this.emergency = emergency;
        this.shifts = shifts;
        this.onlyLongTerm = onlyLongTerm;
        this.splittedMinDays = splittedMinDays;
        this.mayBeSplitted = mayBeSplitted;
        this.endDate = endDate;
        this.startDate = startDate;
        this.functionalArea = functionalArea;
        this.typeOfEmployment = typeOfEmployment;
        this.qualifications = qualifications;
        this.experiences = experiences;
        this.additionalQualifications = additionalQualifications;
        this.finishedResellers = finishedResellers;
        this.applications = applications;
        this.projectKey = projectKey;
        this.publishedForEveryone = publishedForEveryone;
        this.hospitalId = hospitalId;
        this.station = station;
        this.resellers = resellers;
        this.state = state;
        this.dayArrays = dayArrays;
        this.job = job;
    }


    static fromJson(arg0: any, withHospital: boolean = true): Project {
        var returnProject = new Project();
        returnProject.lateShiftTimes = arg0['lateShiftTimes'];
        returnProject.earlyShiftTimes = arg0['earlyShiftTimes'];
        returnProject.nightShiftTimes = arg0['nightShiftTimes'];
        returnProject.info = arg0['info'];
        returnProject.publishedForEveryone = arg0['publishedForEveryone'];
        returnProject.dayArrays = arg0['dayArrays'];
        returnProject.currentlyInHospitalRelevantState = arg0['currentlyInHospitalRelevantState'];
        returnProject.timeline = arg0['timeline'];
        returnProject.chosenUsers = arg0['chosenUsers'];
        returnProject.hospitalId = arg0['hospitalId'];
        returnProject.finishedResellers = arg0['finishedResellers'];
        returnProject.internal = arg0['internal'];
        returnProject.station = arg0['station'];
        returnProject.projectKey = arg0['projectKey'];
        returnProject.mayBeSplitted = arg0['mayBeSplitted'];
        returnProject.splittedMinDays = arg0['splittedMinDays'];
        returnProject.acceptsApplications = arg0['acceptsApplications'];
        returnProject.splitOption = arg0['splitOption'];
        returnProject.shifts = arg0['shifts'];
        returnProject.job = arg0['job'];
        returnProject.onlyLongTerm = arg0['onlyLongTerm'];
        returnProject.emergency = arg0['emergency'];
        returnProject.department = arg0['department'];
        returnProject.state = arg0['state'];
        if (withHospital) {
            returnProject.hospital = arg0['hospital'];
        }
        returnProject.endDate = arg0['endDate'];
        returnProject.startDate = arg0['startDate'];
        returnProject.functionalArea = arg0['functionalArea'];
        returnProject.qualifications = new Array<any>();
        if (arg0['qualifications'] != null) {
            arg0['qualifications'].forEach(element => {
                returnProject.qualifications.push(element);
            });
        }
        returnProject.additionalQualifications = new Array<any>();
        if (arg0['additionalQualifications'] != null) {
            arg0['additionalQualifications'].forEach(element => {
                returnProject.additionalQualifications.push(element);
            });
        }
        returnProject.experiences = new Array<any>();
        if (arg0['experiences'] != null) {
            arg0['experiences'].forEach(element => {
                returnProject.experiences.push(element);
            });
        }
        returnProject.applications = {};
        if (arg0['applications'] != null) {
            var keys = Object.keys(arg0['applications']);
            keys.forEach(element => {
                returnProject.applications[element] = true;
            });
        }
        returnProject.typeOfEmployment = arg0['typeOfEmployment'];
        returnProject.resellers = arg0['resellers'];

        return returnProject;
    }
}
