import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { MatDatepickerInputEvent, MatCalendar } from '@angular/material';

@Component({
  selector: 'app-highlight-calendar',
  templateUrl: './highlight-calendar.component.html',
  styleUrls: ['./uff.css']
})
export class HighlightCalendarComponent implements OnInit, OnChanges {
  @ViewChild(MatCalendar, { static: false }) calendar: MatCalendar<Date>;
  @Output() selected = new EventEmitter<any>();
  private _possibleDatesArray: Array<any>;
  private _currentPosNumber;
  @Input()
  get currentPosNumber() {
    return this._currentPosNumber;
  }
  set currentPosNumber(posNumber) {
    this._currentPosNumber = posNumber;
  }
  @Input()
  get possibleDatesArray(): Array<any> { return this._possibleDatesArray; }
  set possibleDatesArray(arr: Array<any>) {
    this._possibleDatesArray = arr;

    this._setupClassFunction();
  }
  private _distributedDatesArray: Date[];
  @Input()
  get distributedDatesArray(): Date[] { return this._possibleDatesArray; }
  set distributedDatesArray(d: Date[]) {
    this._distributedDatesArray = d;

    this._setupClassFunction();
  }
  @Input() appl: any;

  private _update: Boolean;
  @Input()
  get update() {
    return this._update;
  }
  set update(val) {
    setTimeout(() => {

      this._setupClassFunction();
      if (this.calendar != null) {
        this.calendar.updateTodaysDate();
      }
    }, 200);
  }

  private _selectedDatesArray: Date[];
  @Input()
  get selectedDatesArray(): Date[] { return this._selectedDatesArray; }
  set selectedDatesArray(d: Date[]) {
    this._selectedDatesArray = d;

    this._setupClassFunction();
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this._setupClassFunction();
  }

  specialDates = [];

  dateClass: (d: Date) => any;


  updateDayStyles() {
    let elements = document.querySelectorAll(".state-assigned-by-dispo");
    elements.forEach(y => {
      let div = y.querySelectorAll('div')[0];

      div.classList.add('tooltip');
      let spans = y.querySelectorAll('span');
      if (spans.length > 0) {
        let span = spans[0];
        span.innerHTML = "Zeitarbeiter zugewiesen";
        // console.log("update span", div);
      }
      else {
        let span = document.createElement('span');
        span.innerHTML = "t.title";
        span.classList.add('tooltiptext');
        div.appendChild(span);
      }
    })

    
    let appliedElements = document.querySelectorAll(".state-applied");
    appliedElements.forEach(y => {
      let div = y.querySelectorAll('div')[0];

      div.classList.add('tooltip');
      let spans = y.querySelectorAll('span');
      if (spans.length > 0) {
        let span = spans[0];
        span.innerHTML = "Beworben";
        // console.log("update span", div);
      }
      else {
        let span = document.createElement('span');
        span.innerHTML = "t.title";
        span.classList.add('tooltiptext');
        div.appendChild(span);
      }
    })

    
    let confirmedByUserElements = document.querySelectorAll(".state-confirmed-by-user");
    confirmedByUserElements.forEach(y => {
      let div = y.querySelectorAll('div')[0];

      div.classList.add('tooltip');
      let spans = y.querySelectorAll('span');
      if (spans.length > 0) {
        let span = spans[0];
        span.innerHTML = "Bestätigt von Zeitarbeiter";
        // console.log("update span", div);
      }
      else {
        let span = document.createElement('span');
        span.innerHTML = "t.title";
        span.classList.add('tooltiptext');
        div.appendChild(span);
      }
    })
    
    
    let confirmedByHospitalElements = document.querySelectorAll(".state-contract");
    confirmedByHospitalElements.forEach(y => {
      let div = y.querySelectorAll('div')[0];

      div.classList.add('tooltip');
      let spans = y.querySelectorAll('span');
      if (spans.length > 0) {
        let span = spans[0];
        span.innerHTML = "Bestätigt von Einrichtung";
        // console.log("update span", div);
      }
      else {
        let span = document.createElement('span');
        span.innerHTML = "t.title";
        span.classList.add('tooltiptext');
        div.appendChild(span);
      }
    })
    
    
    let signedElements = document.querySelectorAll(".state-contract-signed");
    signedElements.forEach(y => {
      let div = y.querySelectorAll('div')[0];

      div.classList.add('tooltip');
      let spans = y.querySelectorAll('span');
      if (spans.length > 0) {
        let span = spans[0];
        span.innerHTML = "AüV unterzeichnet";
        // console.log("update span", div);
      }
      else {
        let span = document.createElement('span');
        span.innerHTML = "t.title";
        span.classList.add('tooltiptext');
        div.appendChild(span);
      }
    })
  }

  private _setupClassFunction() {
    this.dateClass = (d: Date) => {

      var classToAppend = undefined;
      if (this._possibleDatesArray) {
        this._possibleDatesArray.forEach(dateElement => {
          var itemDate = this.parseDate(dateElement['date'], null);
          if (itemDate.getFullYear() === d.getFullYear()
            && itemDate.getDate() === d.getDate()
            && itemDate.getMonth() === d.getMonth()) {
            if (dateElement['state'] == 'open') {
              classToAppend = 'example-custom-date-class';
            } if (dateElement['state'] == 'ASSIGNED_BY_DISPO' && dateElement['positionNumber'] == this._currentPosNumber) {
              classToAppend = 'state-assigned-by-dispo';
            } if (dateElement['state'] == 'APPLIED' && dateElement['positionNumber'] == this._currentPosNumber) {
              classToAppend = 'state-applied';
            } if (dateElement['state'] == 'CONFIRMED_BY_USER' && dateElement['positionNumber'] == this._currentPosNumber) {
              classToAppend = 'state-confirmed-by-user';
            } if ((dateElement['state'] == 'CONTRACTUPLOADED' || dateElement['state'] == 'CONFIRMED_BY_HOSPITAL' || dateElement['state'] == 'CONTRACTTRANSMITTED') && dateElement['positionNumber'] == this._currentPosNumber) {
              classToAppend = 'state-contract';
            } if (dateElement['state'] == 'CONTRACTSIGNED' && dateElement['positionNumber'] == this._currentPosNumber) {
              classToAppend = 'state-contract-signed';
            }






            // if (dateElement['state'] == 'distributed' && dateElement['positionNumber'] == this._currentPosNumber) {
            //   classToAppend = 'currently-being-distributed';
            // } if (dateElement['state'] == 'CONFIRMED_BY_HOSPITAL' && dateElement['positionNumber'] == this._currentPosNumber) {
            //   classToAppend = 'state-contract';
            // } if (dateElement['state'].toLowerCase() == 'completed' && dateElement['positionNumber'] == this._currentPosNumber) {
            //   classToAppend = 'example-custom-date-class';
            // } if (dateElement['state'].toLowerCase() == 'contractsigned' && dateElement['positionNumber'] == this._currentPosNumber) {
            //   classToAppend = 'already-distributed-date';
            // }
          }
        })
        // possible = this._possibleDatesArray.some((item: Date) =>
        //   item.getFullYear() === d.getFullYear()
        //   && item.getDate() === d.getDate()
        //   && item.getMonth() === d.getMonth());
      }


      let distributed = false;
      if (this._distributedDatesArray) {
        distributed = this._distributedDatesArray.some((item: Date) =>
          item.getFullYear() === d.getFullYear()
          && item.getDate() === d.getDate()
          && item.getMonth() === d.getMonth());
      }
      if (classToAppend != 'currently-being-distributed' && classToAppend != 'already-distributed-date' && distributed) {


        classToAppend = 'previously-distributed';
      }

      let selected = false;
      if (this._selectedDatesArray) {
        selected = this._selectedDatesArray.some((item: Date) =>
          item.getFullYear() === d.getFullYear()
          && item.getDate() === d.getDate()
          && item.getMonth() === d.getMonth());
      }
      if (selected) {
        classToAppend = 'checked-date';
      }

      return classToAppend;
    }

    this.updateDayStyles();
  }

  addEvent(event) {
    if (this.appl.state == 'APPLIED') {
      this.possibleDatesArray.forEach(possibleDate => {
        if (this.parseDate(possibleDate['date'], null).getTime() == event.getTime()) {
          this.selected.emit({ appl: this.appl, date: event });
        }
      });
    }
  }


  ngOnInit(): void {
  }



  parseDate(dateString, timeString, addAdditionalPreviousWeek?) {
    var y = dateString.substr(0, 4);
    var m = dateString.substr(4, 2) - 1;

    var d = dateString.substr(6, 2);
    var hour = 0;
    var min = 0;
    if (timeString != null && timeString != "") {

      hour = timeString.substr(0, 2);
      min = timeString.substr(3, 2);

    }
    // var y = str.substr(0, 4),
    //   m = str.substr(4, 2) - 1,
    //   d = str.substr(6, 2);
    var D = new Date(y, m, d, hour, min);

    if (addAdditionalPreviousWeek) {
      var date = new Date(D.valueOf());
      date.setDate(date.getDate() - 7);
      return date;
    }
    return D;
  }
}
