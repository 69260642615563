import { Project } from '../../projects/projects/project.model';

export class Hospital {
    addReseller(resellerId: string) {
        if (this.resellers == null) {
            this.resellers = Array<string>();
        }
        this.resellers.push(resellerId);
    }

    static transformToPlainJsObject(hospital: Hospital): Object {
        if (hospital.projects != null) {
            var projects = [];
            hospital.projects.forEach(project => {
                projects.push(Project.transformToPlainJsObject(project));
            });
            hospital.projects = projects;
        }
        return Object.assign({}, hospital);
    }

    public id: string;
    public name: string;
    public address: string;
    public earlyShiftTimes: string;
    public lateShiftTimes: string;
    public nightShiftTimes: string;
    public cityCode: string;
    public email: string;
    public projects: Project[];
    public resellers: Array<string>;
    public isTestHospital: boolean;
    public phone: {};
    public timeTrackingPassword: string;
    public testMode: boolean;
    public companyType: string;


    constructor(testMode?: boolean, companyType?: string, isTestHospital?: boolean, cityCode?: string, id?: string, name?: string, phone?: string, address?: string, projects?: any[], nightShiftTimes?: string, lateShiftTimes?: string, earlyShiftTimes?: string, timeTrackingPassword?: string, email?: string, resellers?: Array<string>);
    constructor(testMode: boolean, companyType: string, isTestHospital: boolean, cityCode: string, id: string, name: string, phone: string, address: string, projects: Project[], nightShiftTimes: string, lateShiftTimes: string, earlyShiftTimes: string, timeTrackingPassword: string, email: string, resellers: Array<string>) {
        this.name = name;
        this.address = address;
        this.phone = phone;
        this.resellers = resellers;
        this.testMode = testMode;
        this.companyType = companyType;
        this.projects = projects;
        this.nightShiftTimes = nightShiftTimes;
        this.cityCode = cityCode;
        this.lateShiftTimes = lateShiftTimes;
        this.earlyShiftTimes = earlyShiftTimes;
        this.timeTrackingPassword = timeTrackingPassword;
        this.email = email;
        this.isTestHospital = isTestHospital;
    }

    static fromJson(arg0: any): Hospital {
        var returnHospital = new Hospital();
        returnHospital.id = arg0['id'];
        returnHospital.name = arg0['name'];
        returnHospital.phone = arg0['phone'];
        returnHospital.testMode = arg0['testMode'];
        returnHospital.cityCode = arg0['cityCode'];
        returnHospital.resellers = arg0['resellers'];
        returnHospital.isTestHospital = arg0['isTestHospital'];
        returnHospital.email = arg0['email'];
        returnHospital.address = arg0['address'];
        returnHospital.nightShiftTimes = arg0['nightShiftTimes'];
        returnHospital.lateShiftTimes = arg0['lateShiftTimes'];
        returnHospital.companyType = arg0['companyType'];
        returnHospital.earlyShiftTimes = arg0['earlyShiftTimes'];
        returnHospital.timeTrackingPassword = arg0['timeTrackingPassword'];
        for (var key in arg0.projects) {
            if (returnHospital.projects == null) {
                returnHospital.projects = new Array<Project>();
            }
            if (arg0.projects.hasOwnProperty(key)) {
                var element = arg0.projects[key];
                returnHospital.projects.push(Project.fromJson(element));
            }
        }

        return returnHospital;
    }
}
