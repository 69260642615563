import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'app/shared/services/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseService } from 'app/shared/services/firebase.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-addshift',
  templateUrl: './addshift.component.html',
  styleUrls: ['./addshift.component.scss']
})
export class AddshiftComponent implements OnInit {

  @Input() public cityCode;

  updated = false;
  selectedDates = Array<any>();

  possibleDays = Array<any>();
  possibleUsers = Array<any>();
  selectedUser: any;
  selected = 'option2';

  shiftForm = new FormGroup({
    name: new FormControl(''),
    lateShiftTimesEnd: new FormControl(''),
    lateShiftTimesStart: new FormControl(''),
    earlyShiftTimesEnd: new FormControl(''),
    earlyShiftTimesStart: new FormControl(''),
    nightShiftTimesStart: new FormControl(''),
    nightShiftTimesEnd: new FormControl(''),
  })


  ModalTitle = '';
  constructor(private userService: UserService,
    public activeModal: NgbActiveModal,
    private firebaseService: FirebaseService
  ) {

  }

  ngOnInit() {
  }

  close() {
    this.activeModal.dismiss();
  }

  save() {
    var values = this.shiftForm.value;
    this.activeModal.close(values);
  }
}
