import { Component, OnInit, Input } from '@angular/core';
import { Qualifications } from 'app/projects/projects/qualifications';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-addza',
    templateUrl: './addza.component.html',
    styleUrls: ['./addza.component.scss']
})
export class AddzaComponent implements OnInit {
    possibleQualifications = Array<any>();

    private selectedQualifications = Array<any>();

    ModalTitle = '';
    myForm: FormGroup;
    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder
    ) {

    }

    ngOnInit() {
        const keys = Object.keys(Qualifications);
        keys.forEach(key => {
            this.possibleQualifications.push({ key: key, val: Qualifications[key] })
        });
        this.buildItemForm();
        this.ModalTitle = "Zeitarbeiter hinzufügen";
    }

    private buildItemForm() {
        this.myForm = this.formBuilder.group({
            email: ["" , Validators.required]
        });
    }

    changeQualification(qual) {
        var alreadyChosen = false;
        var indexOfElement = 0;
        var index = 0;
        this.selectedQualifications.forEach(element => {
            if (element.key == qual.key) {
                alreadyChosen = true;
                indexOfElement = index;
            }
            index++;
        });
        if (!alreadyChosen) {
            this.selectedQualifications.push(qual);
        } else {
            this.selectedQualifications.splice(indexOfElement, 1);
        }
    }

    doesHaveQualification(qual) {
        var doesExist = false;
        this.selectedQualifications.forEach(element => {
            if (element.key == qual.key) {
                doesExist = true;
            }
        });
        return doesExist;
    }

    submitForm() {
        var values = this.myForm.value;
        values.qualifications = this.selectedQualifications;
        this.activeModal.close(values);
    }
}
