// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    // Your web app's Firebase configuration
    apiKey: "AIzaSyAm9lqic-tVY6I5RaeNEXUJbUia7Z12YhY",
    authDomain: "bbflutter-1a260.firebaseapp.com",
    databaseURL: "https://bbflutter-1a260.firebaseio.com",
    projectId: "bbflutter-1a260",
    storageBucket: "bbflutter-1a260.appspot.com",
    messagingSenderId: "629721792224",
    appId: "1:629721792224:web:7eceaec4cba28cffaf5a85",
    measurementId: "G-J7VSMEYV03"
    // Initialize Firebase
  }
};


  // The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//   production: false,
//   firebase: {
//     // Your web app's Firebase configuration
//   apiKey: "AIzaSyAAl7ts9xhGaFSGk3SONL4ajlmudHSFJYg",
//   authDomain: "shyftbeta.firebaseapp.com",
//   databaseURL: "https://shyftbeta.firebaseio.com",
//   projectId: "shyftbeta",
//   storageBucket: "shyftbeta.appspot.com",
//   messagingSenderId: "461692406596",
//   appId: "1:461692406596:web:7141101e437b3e11bee511",
//   measurementId: "G-02BLDHMHVR"
//     // Initialize Firebase
//   }
// };
