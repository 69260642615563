import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { Upload } from '../upload';
import { AngularFireStorage } from '@angular/fire/storage';
@Injectable()
export class UploadService {

  constructor(private angularFireStorage: AngularFireStorage, private db: AngularFireDatabase) { }

  private basePath: string = '/uploads';
  uploads: Observable<Upload[]>;

  // Resumee
  // uploadResumee(resellerId: string, email: string, file: File) {
  //   email = email.replace('.', ',');
  //   this.angularFireStorage.ref(`${resellerId}/${email}/resumee`).put(file);
  // }

  // downloadResumee(resellerId: string, email: string): Observable<any> {
  //   email = email.replace('.', ',');
  //   return this.angularFireStorage.ref(`${resellerId}/${email}/resumee`).getDownloadURL();
  // }

  // Hospital Image
  // async uploadHospitalImage(hospitalKey: string, file: File) {
  //   var a = await this.angularFireStorage.ref(`/hospitals/${hospitalKey}/hospitalImage`).put(file);
  //   var b = await a.ref.getDownloadURL();
  //   return b;
  // }

  uploadHospitalLogo(hospitalId: string, file: File) {
    return this.angularFireStorage.ref(`${hospitalId}/hospitalLogo`).put(file);
  }

  downloadHospitalImage(hospitalKey: string): Observable<any> {
    return this.angularFireStorage.ref(`${hospitalKey}/hospitalLogo`).getDownloadURL();
  }

  // Profile Picture
  async uploadProfilePicture(email: string, file: File) {
    email = email.split('.').join(',');
    var a = await this.angularFireStorage.ref(`${email}/profilepicture`).put(file);
    var b = await a.ref.getDownloadURL();
    return b;
  }

  downloadProfilePicture(email: string): Observable<any> {
    email = email.split('.').join(',');
    return this.angularFireStorage.ref(`/${email}/profilepicture`).getDownloadURL();
  }

  // Portfolio
  async uploadFileForUser(resellerId: string, email: string, file: File) {
    if (email != null) {
      email = email.split('.').join(',');
      var task = this.angularFireStorage.ref(`${resellerId}/${email}/portfolio/${file.name}`).put(file);
      return await task;
    }
  }

  getAllFilesForUser(resellerId: string, email: string) {
    var ref = this.angularFireStorage.storage.ref(`${resellerId}/${email}/portfolio`);
    return ref.listAll();

  }

  downloadFileForUser(resellerId: string, email: string, fileName): Observable<any> {
    email = email.split('.').join(',');
    return this.angularFireStorage.ref(`${resellerId}/${email}/portfolio/${fileName}`).getDownloadURL();
  }

  // Unsigned Contract
  uploadUnsignedContract(resellerId: string, projectKey: string, userKey: string, file: File) {
    this.angularFireStorage.ref(`${resellerId}/projects/${projectKey}/${userKey}/unsignedContract`).put(file);
  }

  downloadUnsignedContract(resellerId: string, projectKey: any, userKey: string) {
    return this.angularFireStorage.ref(`${resellerId}/projects/${projectKey}/${userKey}/unsignedContract`).getDownloadURL();
  }

  downloadSignedContractWithoutEAUV(resellerId: string, projectKey: any, userKey: string) {
    return this.angularFireStorage.ref(`${resellerId}/projects/${projectKey}/${userKey}/signedContract`).getDownloadURL();
    // return this.angularFireStorage.ref('/test/test').getDownloadURL();
  }

  getTimeSheetDownloadUrl(path) {
    return this.angularFireStorage.ref(path).getDownloadURL();
  }

  // Signed Contract
  uploadSignedContract(resellerId: string, projectKey: string, userKey: string, file: File) {
    this.angularFireStorage.ref(`${resellerId}/projects/${projectKey}/${userKey}/signedContract`).put(file);
  }

  async uploadTimesheet(path: string, timesheetName: string, file: File) {
    var unsignedPath = path + "/unsigned/" + timesheetName;
    var signedPath = path + "/signed/" + timesheetName;
    await this.angularFireStorage.ref(unsignedPath).delete();
    return this.angularFireStorage.ref(signedPath).put(file);
  }

  downloadSignedContract(envelopeId) {
    return this.angularFireStorage.ref(`contracts/${envelopeId}`).getDownloadURL();
    // return this.angularFireStorage.ref('/test/test').getDownloadURL();
  }

  // Contract Template
  uploadContractTemplate(resellerId: string, file: File) {
    this.angularFireStorage.ref(`${resellerId}/contractTemplate`).put(file);
  }

  downloadContractTemplate(resellerId: string) {
    return this.angularFireStorage.ref(`${resellerId}/contractTemplate`).getDownloadURL();

  }

  uploadResellerLogo(resellerId: string, file: File) {
    this.angularFireStorage.ref(`${resellerId}/resellerLogo`).put(file);
  }

  downloadResellerLogo(resellerId: string) {
    return this.angularFireStorage.ref(`${resellerId}/resellerLogo`).getDownloadURL();
  }
}