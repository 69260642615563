import { NgModule } from "@angular/core";

import { NgbModule, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig } from '@angular/material';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { DragulaService } from "ng2-dragula";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FirebaseService } from './shared/services/firebase.service';
import { SocialLoginModule, AuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { ProjectsComponent } from './projects/projects/projects.component';
import { MdlDatePickerModule } from '@angular-mdl/datepicker';
import { NgDatepickerModule } from 'ng2-datepicker';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ZAComponent } from './users/users/za.component';
import {MatButtonModule} from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { UploadService } from './shared/services/upload.service';
import { ProfileComponent } from './profile/profile/profile.component';
import { AddzaComponent } from './popups/addza/addza.component';
import { AddUserComponent } from './popups/adduser/adduser.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ApplyComponent } from './popups/apply/apply.component';
import { MessageComponent } from './popups/message/message.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatIconModule } from '@angular/material';
import { ForceapplicationComponent } from './popups/forceapplication/forceapplication.component';
import { AssignDaysCalendarComponent } from './shared/assigndays-calendar/assigndays-calendar.component';
import { ShareprojectComponent } from './popups/shareproject/shareproject.component';
import { AdduserforcitycodeComponent } from './popups/adduserforcitycode/adduserforcitycode.component';
import { DocusignemailsComponent } from './popups/docusignemails/docusignemails.component';
import { SearchPipe } from './shared/pipes/search.pipe';
import { OnCreate } from './directives/onCreate';
import { IdnowService } from './shared/services/idnow.service';
import { ChangeProjectStatePopUpComponent } from './popups/change-project-state-pop-up/change-project-state-pop-up.component';
import { AngularFireAnalyticsModule, UserTrackingService } from '@angular/fire/analytics';
import { InfopopupComponent } from './popups/infopopup/infopopup.component';
import { AddshiftComponent } from './popups/addshift/addshift.component';
import { ChatComponent } from './chat/chat.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

const facebook_oauth_client_id: string = 'Your-facebook-client-id.';
let config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('426104144970895')
  }
]);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    AddzaComponent,
    ProfileComponent,
    AddUserComponent,
    ApplyComponent,
    MessageComponent,
    ForceapplicationComponent,
    ShareprojectComponent,
    AdduserforcitycodeComponent,
    SearchPipe,
    OnCreate,
    DocusignemailsComponent,
    ChangeProjectStatePopUpComponent,
    InfopopupComponent,
    AddshiftComponent],
  imports: [
    MdlDatePickerModule,
    SocialLoginModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    NoopAnimationsModule,
    MatButtonModule,
    AngularFirestoreModule,
    NgDatepickerModule,
    AngularFireAnalyticsModule,
    BrowserModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,
    NgbModule,
    MatInputModule,
    AngularFireAuthModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    FormsModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAkE5arCqIim54RFZAf-8ps2lw2f-clxRE"
    }),
    PerfectScrollbarModule
  ],

  entryComponents: [
    AssignDaysCalendarComponent,
    MessageComponent,
    AddzaComponent,
    AddUserComponent,
    ForceapplicationComponent,
    ShareprojectComponent,
    AdduserforcitycodeComponent,
    AddshiftComponent,
    DocusignemailsComponent, 
    ChangeProjectStatePopUpComponent,
    InfopopupComponent
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    NgbActiveModal,
    UserTrackingService,
    UploadService,
    DatePipe,
    FirebaseService,
    IdnowService,
    AuthService,
    AuthGuard,
    DragulaService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: AuthServiceConfig, useFactory: provideConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
