export enum UserPermissions {
    INVITE_USERS = "Standortmitarbeiter einladen",
    EDIT_USERS = "Standortmitarbeiter verwalten",
    CHANGE_PERMISSIONS = "Standortmitarbeiter Berechtigungen verwalten",
    INVITE_ZA = "Zeitarbeiter einladen",
    EDIT_ZA = "Zeitarbeiter verwalten",
    MANAGE_DOCUMENTS = "AÜV",
    SIGN_DIGITAL = "AüV digital signieren",
    MANAGE_PROJECTS = "Projekte verwalten",
}

export enum CustomerUserPermissions {
    INVITE_USERS = "Mitarbeiter einladen",
    EDIT_USERS = "Mitarbeiter verwalten",
    CHANGE_PERMISSIONS = "Mitarbeiter Berechtigungen verwalten",
    MANAGE_DOCUMENTS = "AÜV",
    SIGN_DIGITAL = "AüV digital signieren",
    MANAGE_PROJECTS = "Projekte verwalten"
}