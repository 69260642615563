import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent, MatCalendar } from '@angular/material';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-assigndays-calendar',
  templateUrl: './assigndays-calendar.component.html',
  styleUrls: ['./uff.css']
})
export class AssignDaysCalendarComponent implements OnInit {
  @ViewChild(MatCalendar, { static: false }) calendar: MatCalendar<Date>;
  @Output() selected = new EventEmitter<any>();
  private _possibleDatesArray: Array<any>;
  private _currentPosNumber;
  @Input()
  get currentPosNumber() {
    return this._currentPosNumber;
  }
  @Input()
  get possibleDatesArray(): Array<any> { return this._possibleDatesArray; }
  set possibleDatesArray(arr: Array<any>) {
    this._possibleDatesArray = arr;

    this._setupClassFunction();
  }

  @Input() appl: any;
  @Input() splitOption: any;

  constructor(public datepipe: DatePipe) {

  }

  private _update: Boolean;
  @Input()
  get update() {
    return this._update;
  }
  set update(val) {
    setTimeout(() => {

      this._setupClassFunction();
      if (this.calendar != null) {
        this.calendar.updateTodaysDate();
      }

    }, 200);
  }

  private _selectedDatesArray: Date[];
  @Input()
  get selectedDatesArray(): Date[] { return this._selectedDatesArray; }
  set selectedDatesArray(d: Date[]) {
    this._selectedDatesArray = d;

    this._setupClassFunction();
  }

  dateClass: (d: Date) => any;

  private _setupClassFunction() {
    this.dateClass = (d: Date) => {

      var classToAppend = undefined;
      if (this._possibleDatesArray) {
        this._possibleDatesArray.forEach(dateElement => {
          var itemDate = this.parseDate(dateElement['date'], null);
          if (itemDate.getFullYear() === d.getFullYear()
            && itemDate.getDate() === d.getDate()
            && itemDate.getMonth() === d.getMonth()) {
            if (dateElement['state'] == 'open') {
              classToAppend = 'example-custom-date-class';
            } if (dateElement['state'].toLowerCase() == 'distributed' && dateElement['positionNumber'] == this._currentPosNumber) {
              classToAppend = 'currently-being-distributed';
            } if (dateElement['state'].toLowerCase() == 'completed' && dateElement['positionNumber'] == this._currentPosNumber) {
              classToAppend = 'example-custom-date-class';
            } if (dateElement['state'].toLowerCase() == 'contractsigned' && dateElement['positionNumber'] == this._currentPosNumber) {
              classToAppend = 'already-distributed-date';
            }
          }
        })
      }

      let selected = false;
      if (this._selectedDatesArray) {
        selected = this._selectedDatesArray.some((item: Date) =>
          item.getFullYear() === d.getFullYear()
          && item.getDate() === d.getDate()
          && item.getMonth() === d.getMonth());
      }
      if (selected) {
        classToAppend = 'checked-date';
      }

      return classToAppend;
    }
  }

  addEvent(event) {
    console.log(event);
    this.possibleDatesArray.forEach(possibleDate => {
      if (this.parseDate(possibleDate['date'], null).getTime() == event.getTime()) {

        var dates = [];
        var day = this.parseDate(possibleDate['date'], null);
        // if splitOption == days mark the day
        console.log(this.splitOption);
        if (this.splitOption == 'days') {
          dates.push(day);
        } else if (this.splitOption == 'weeks') {
          var firstDay = day.getDate() - day.getDay();
          var monday = new Date(day.setDate(firstDay));
          for (var i = 0; i < 7; i++) {
            var date = new Date(monday.getTime());
            date.setDate(monday.getDate() + i);
            if (this.possibleDatesArray.some(element => element['date'] == this.datepipe.transform(date, "yyyyMMdd"))) {
              dates.push(date);
            }
          }
        } else if (this.splitOption == 'months') {
          var firstDayOfMonth = new Date(day.getFullYear(), day.getMonth(), 1);
          var lastDayOfMonth = new Date(day.getFullYear(), day.getMonth() + 1, 0);
          var monday = new Date(firstDayOfMonth);
          var i = 0;
          while (lastDayOfMonth > firstDayOfMonth) {
            firstDayOfMonth.setDate(monday.getDate() + i);
            var dateToAdd = new Date(firstDayOfMonth);
            if (this.possibleDatesArray.some(element => element['date'] == this.datepipe.transform(dateToAdd, "yyyyMMdd"))) {
              dates.push(dateToAdd);
            }
            i++;
          }

        }
        this.selected.emit({ dates: dates });
      }
    });
  }

  ngOnInit(): void {
  }

  parseDate(dateString, timeString, addAdditionalPreviousWeek?) {
    var y = dateString.substr(0, 4);
    var m = dateString.substr(4, 2) - 1;

    var d = dateString.substr(6, 2);
    var hour = 0;
    var min = 0;
    if (timeString != null && timeString != "") {

      hour = timeString.substr(0, 2);
      min = timeString.substr(3, 2);

    }
    // var y = str.substr(0, 4),
    //   m = str.substr(4, 2) - 1,
    //   d = str.substr(6, 2);
    var D = new Date(y, m, d, hour, min);

    if (addAdditionalPreviousWeek) {
      var date = new Date(D.valueOf());
      date.setDate(date.getDate() - 7);
      return date;
    }
    return D;
  }


}
