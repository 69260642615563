import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-infopopup',
  templateUrl: './infopopup.component.html',
  styleUrls: ['./infopopup.component.scss']
})
export class InfopopupComponent implements OnInit {
  @Input() public title: string;
  @Input() public text: string;

  step = 0;
  eauv = false;
  hasEAUVModule = false;
  stateToSet: string;

  shouldShowAuVCreatedButton = false;
  shouldShowAuVSentForResellerButton = false;
  shouldShowAuVSentForHospitalButton = false;
  shouldShowAuVSignedButton = false;

  ModalTitle = '';
  constructor(
    public activeModal: NgbActiveModal
  ) {

  }

  ngOnInit() {
    this.asyncInit();
  }

  async asyncInit() {
  }

  close() {
    this.activeModal.close({"done": true});
  }
}
