import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Http, ResponseContentType } from '@angular/http';
import * as fileSaver from 'file-saver';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class IdnowService {

  API_KEY = "FF9875JBVEA7W5MW5QZG1K622CIX2Z8AWQ3PGJ75";
  GATEWAY_URL = 'https://gateway.test.idnow.de';
  CUSTOMER = "shyftesign";

  constructor(private angularFireStorage: AngularFireStorage, private db: AngularFireDatabase, private http: HttpClient) { }

  async createIdNowAccountDocument(userData, projectKey, userKey) {
    var identId = await this.createIDNOWAccountIdent(userData, projectKey, userKey);
    await this.uploadIDNowInitDocument(identId);
    return identId;
  }

  async createNewIdentAndUploadDocument(file, userData, signatureType, projectKey, userKey) {
    var identId = await this.createIdent(userData, signatureType, projectKey, userKey);
    await this.uploadDocument(file, identId, signatureType);
    return identId;
  }

  async downloadResellerAuVAndStartHospitalIdent(contractId, userData, projectKey, userKey) {
    var ref = this.angularFireStorage.ref(`/contracts/${contractId}`).getDownloadURL();
    var url = await ref.toPromise();
    var file = await this.getContract(url);

    return await this.createNewIdentAndUploadDocument(file, userData, "HOSPITAL", projectKey, userKey);
  }

  getContract(url) {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.open('get', url, true);
      xhr.onload = function () {
        var status = xhr.status;
        if (status == 200) {
          resolve(xhr.response);
        } else {
          reject(status);
        }
      };
      xhr.send();
    });
  }

  async uploadDocument(file, identToken, signertype) {
    var loginToken = await this.getLoginToken();
    let url = `${this.GATEWAY_URL}/api/v1/${this.CUSTOMER}/identifications/${identToken}/documents/resellerAuV/data`;
    if (signertype == 'HOSPITAL') {
      url = `${this.GATEWAY_URL}/api/v1/${this.CUSTOMER}/identifications/${identToken}/documents/hospitalAuV/data`;
    }
    let headers = new HttpHeaders({ 'Content-Type': 'application/pdf', 'X-API-LOGIN-TOKEN': loginToken });

    let body = file;

    var responseBody = await this.http.post(url, body, { headers: headers }).toPromise();
    return responseBody;
  }

  async uploadIDNowInitDocument(identToken) {

    var res = await this.http.get('/assets/pdf/idnowsignup.pdf', { 'responseType': 'blob' }).toPromise();

    var loginToken = await this.getLoginToken();
    let url = `${this.GATEWAY_URL}/api/v1/${this.CUSTOMER}/identifications/${identToken}/documents/idnowshyftinstructions/data`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/pdf', 'X-API-LOGIN-TOKEN': loginToken });

    let body = res;

    var responseBody = await this.http.post(url, body, { headers: headers }).toPromise();
    return responseBody;
  }

  async createIDNOWAccountIdent(userData, projectKey, userKey) {
    var transactionNumber = this.getTransactionNumber('init', projectKey, userKey);
    var loginToken = await this.getLoginToken();
    let url = `${this.GATEWAY_URL}/api/v1/${this.CUSTOMER}/identifications/${transactionNumber}/start`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-API-LOGIN-TOKEN': loginToken });

    userData = {
      "birthday": "1999-01-01",
      "birthplace": "München",
      "city": "München",
      "country": "DE",
      "custom1": "X-MANUALTEST-HAPPYPATH",
      "custom2": "Custom fields",
      "custom3": "can set testbot scenarios",
      "custom4": "or store any data strings",
      "custom5": "you like.",
      "email": "reseller@shyftapp.de",
      "firstname": "Testthe",
      "gender": "MALE",
      "lastname": "Hospital",
      "mobilephone": "+49",
      "nationality": "DE",
      "street": "Bahnhofstraße",
      "zipcode": "80807"
    }

    let body = JSON.stringify(userData);

    var responseBody = await this.http.post(url, body, { headers: headers }).toPromise();
    var identId = responseBody['id'];
    return identId;
  }


  async createIdent(userData, type, projectKey, userKey) {
    var transactionNumber = this.getTransactionNumber(type == 'RESELLER' ? 'r' : 'h', projectKey, userKey);
    var loginToken = await this.getLoginToken();
    // var transactionNumber = await this.getTransactionNumber();
    let url = `${this.GATEWAY_URL}/api/v1/${this.CUSTOMER}/identifications/${transactionNumber}/start`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-API-LOGIN-TOKEN': loginToken });

    if (type == 'RESELLER') {
      userData = {
        "birthday": "1999-01-01",
        "birthplace": "München",
        "city": "München",
        "country": "DE",
        "custom1": "X-MANUALTEST-HAPPYPATH",
        "custom2": "Custom fields",
        "custom3": "can set testbot scenarios",
        "custom4": "or store any data strings",
        "custom5": "you like.",
        "email": "reseller@shyftapp.de",
        "firstname": "Testthe",
        "gender": "MALE",
        "lastname": "Reseller",
        "mobilephone": "+49",
        "nationality": "DE",
        "street": "Bahnhofstraße",
        "zipcode": "80807"
      };
    } else {
      userData = {
        "birthday": "1999-01-01",
        "birthplace": "München",
        "city": "München",
        "country": "DE",
        "custom1": "X-MANUALTEST-HAPPYPATH",
        "custom2": "Custom fields",
        "custom3": "can set testbot scenarios",
        "custom4": "or store any data strings",
        "custom5": "you like.",
        "email": "reseller@shyftapp.de",
        "firstname": "Testthe",
        "gender": "MALE",
        "lastname": "Hospital",
        "mobilephone": "+49",
        "nationality": "DE",
        "street": "Bahnhofstraße",
        "zipcode": "80807"
      };
    }

    let body = JSON.stringify(userData);

    var responseBody = await this.http.post(url, body, { headers: headers }).toPromise();
    var identId = responseBody['id'];
    return identId;
  }


  async getLoginToken() {
    var url = `${this.GATEWAY_URL}/api/v1/${this.CUSTOMER}/login`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var body = JSON.stringify({ 'apiKey': this.API_KEY });

    var responseBody = await this.http.post(url, body, { headers: headers }).toPromise();
    return responseBody['authToken'];
  }

  async createDocumentDefinition(documentName, identifier) {
    var loginToken = await this.getLoginToken();
    let url = `${this.GATEWAY_URL}/api/v1/${this.CUSTOMER}/documentDefinitions`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-API-LOGIN-TOKEN': loginToken });
    let body = JSON.stringify({ "name": documentName, "identifier": identifier, "mimeType": "application/pdf" });

    await this.http.post(url, body, { headers: headers }).toPromise();
  }

  async downloadDocumentFile(identId) {
    try {

    } catch (e) {
      var loginToken = await this.getLoginToken();
      let url = `${this.GATEWAY_URL}/api/v1/${this.CUSTOMER}/identifications/${identId}/documents/resellerAuV/signed`;
      let headers = new HttpHeaders({ 'Content-Type': 'application/pdf', 'X-API-LOGIN-TOKEN': loginToken });

      var options = {
        headers: headers,
        responseType: 'blob' as 'blob'
      }

      var responseBody = await this.http.get(url, options).toPromise();

      let blob: any = new Blob([responseBody], { type: 'text/json; charset=utf-8' });

      fileSaver.saveAs(blob, 'test.pdf');
    }
  }

  async getAuVSignedByReseller() {

  }

  getTransactionNumber(typeIndicator, projectKey, userKey) {
    var ran = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
    return typeIndicator + '-' + projectKey + '-' + userKey + "-" + ran;
  }
}
