import { data } from 'app/shared/data/smart-data-table';
import { Project } from 'app/projects/projects/project.model';

export class Message {
    title: string;
    text: string;
    receiverName: string;
    type: string;
    recipientId: string;
    senderId: string;
    link: string;
    senderName: string;
    dateAdded: number;

    public static fromJson(json): Message {
        if (!json) {
            return new Message();
        }
        var message = new Message();
        message.title = json.title;
        message.link = json.link;
        message.text = json.text;
        message.type = json.type;
        message.receiverName = json.receiverName;
        message.recipientId = json.recipientId;
        message.senderId = json.senderId;
        message.senderName = json.senderName;
        message.dateAdded = json.dateAdded;
        return message;
    }

    public toJson() {
        var values = {};
        values['link'] = this.link;
        values['title'] = this.title;
        values['type'] = this.type;
        values['text'] = this.text;
        values['receiverName'] = this.receiverName;
        values['recipientId'] = this.recipientId;
        values['senderId'] = this.senderId;
        values['senderName'] = this.senderName;
        values['dateAdded'] = this.dateAdded;
        return values;
    }
}